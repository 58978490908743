import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import { areaService } from '../../../../service/areaServices';
import { userService } from '../../../../service/plateServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { visualInspection } from '../../../../service/visualinspection';
import baseUrl from '../../../../service';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../widgets/table';
import Pagination from '../../../../widgets/pagination';
import { personnelMonitoringColumns } from './partials/columns';
import { reportServices } from '../../../../service/reportServices';

interface ActiveAirSamplingReportProps {
  ishide?: boolean;
}


const PersonnelMonitoring: React.FC<ActiveAirSamplingReportProps> = ({ ishide }) => { 

  type Filters = {
    location: string;
    batchCode: string;
    lotCode: string;
    plateSerialNumber: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

 const { t } = useTranslation()
 const [filters, setFilters] = useState({
    location: '',
    batchCode: '',
    lotCode: '',
    plateSerialNumber: '',
    fromDate: '',
    toDate: '',
  });
  const [areas, setAreas] = useState<Array<any>>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [plateBatches, setPlateBatches] = useState([]);
  const [lotcode, setLotCode] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);


  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      if (name === "batchCode") {
        lotCode((eventOrValue as SingleValue<OptionType>)?.value || '');
      }
      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    setIsButtonDisabled(!anyFilterChanged);
    setIsApplyClicked(false)
    setShowDownloadCSV(true)
  };
  
  const geAreaTypeList = async () => {
    try {  
      const response = await areaService.getParentAreaList([10]);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    slno: index + 1, 
                    policy_id : element.policy_id,
                    id : element.id,
                    title: element.name,
                };
              });
              setAreas(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //batch list for filter dropdown 
  const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  }

  const lotCode = async (batchNo: string) => {
    try {
      const response = await visualInspection.listFilterLots(batchNo);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotCode(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const listSerialNumber = async () => {
    try {
      const response = await userService.listSerialNumber();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.serial_number,
                    label: element.serial_number ,
                };
              });
              setPlateSerielNums(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const handleApplyClick =() =>{
    setShowDownloadCSV(false)
    setIsApplyClicked(true)
    getPersonnelMonitoringPlateReport();
    // listAuditTrialReport();
  }

  const handleCancel = () => {
    const initialFormData = {
      location: '',
      batchCode: '',
      lotCode: '',
      plateSerialNumber: '',
      fromDate: '',
      toDate: '',
    };
    setFilters(initialFormData);
    getPersonnelMonitoringPlateReport();
    // listAuditTrialReport();
  }

  //api call to list personnel monitoring report
  const getPersonnelMonitoringPlateReport = async () => {
    try {
      const response = await reportServices.personnelMonitoringReport(page, pageSize, filters.fromDate, filters.toDate, filters.location, filters.batchCode, filters.lotCode, filters.plateSerialNumber);
        if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
    
                const statusNumber = parseInt(element.location_type, 10); 
                return { 
                  id : element.id,
                  slno: index + 1,
                  operator_id: element.operator_id,
                  plate_id: element.plate_serial,
                  person_name : element.person_name,
                  location : element.location,
                  monitoring_type : element.monitoring_type,
                  sampling_start_date : element.sampling_start_date,
                  incubation_started_by: element.incubation_started_by,
                  incubation_start_date: element.incubation_start_date,
                  incubation_end_by: element.incubation_end_by,
                  incubation_end_date: element.incubation_end_date,
                  incubation_duration: element.incubation_duration,
                  observer1_name: element.observer1_name,
                  observer2_name: element.observer2_name,
                  count1: element.count1,
                  count2: element.count2,
                  approved_id: element.approved_id,
                };
                });
                setPlateDetails(rowData);
            }
            } catch (error) {
            console.log(error); 
            }
        } else {
        setPlateDetails([])
        setTotalPages(0)
        setTotalElements(0)
        }
    } catch (error) {
        console.error('Plate response', error);
    }
    };


  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  useEffect (() => {
    getPersonnelMonitoringPlateReport();
    geAreaTypeList();
    listPlateBatch();
    listSerialNumber();
    setUseEffectRequired(false)
  },[useEffectRequired])
  

const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/personal_monitoring?area_master_id=${filters.location}&batch_number=${filters.batchCode}&lot_id=${filters.lotCode}&serial_number=${filters.plateSerialNumber}&from_date=${filters.fromDate}&to_date=${filters.toDate}`
    window.location.href = link;
    setShowDownloadCSV(true)
    setIsApplyClicked(true)
};


  return (
    <div>
     {!ishide && (
        <>
      <HeaderSection />
      <PageHeader pageHeader="Personnel Monitoring Report" />
      </>
      )}
      <div className="filter-component">
        <div className="filter-row">
          <label className="filter-label">
            Location
            <select name="location" value={filters.location} onChange={handleChange} className="filter-select">
              <option value="">Select Location</option>
              {areas?.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.title}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="filter-row">
        <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateBatches}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Batch code"
            isSearchable={true}
            isClearable
            value={plateBatches.find((option: any) => option.value === filters.batchCode) || null}
            name="batchCode"
          />
        </div>        
      </div>

      <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotcode}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            value={lotcode.find((option: any) => option.value === filters.lotCode) || null}
            name="lotCode"
          />
        </div>        
      </div>

      <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Serial Number</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateSerielNum}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Serial Number"
            isSearchable={true}
            isClearable
            value={plateSerielNum.find((option: any) => option.value === filters.plateSerialNumber) || null}
            name="plateSerialNumber"
          />
        </div>        
      </div>

      <div className="filter-row">
        <label className="filter-label">
          From Date
          <input type="date" name="fromDate" value={filters.fromDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          To Date
          <input type="date" name="toDate" value={filters.toDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      {/* <div className="filter-row">
        <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Personnel Monitoring Report</button>
      </div> */}
      </div>

      <div className="mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={handleCancel} >{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={downloadAuditCSV}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>
        </div>

        <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
            data={plateDetails}
            columns={personnelMonitoringColumns}
            isEditable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            tableHead={'Personnel Monitoring Report'}
            showBatchFilter={false}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

    </div>
  )
}

export default PersonnelMonitoring;