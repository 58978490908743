import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import { areaService } from '../../../../service/areaServices';
import { userService } from '../../../../service/plateServices';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { visualInspection } from '../../../../service/visualinspection';
import baseUrl from '../../../../service';
import { equipments } from '../../../../service/equipments';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../widgets/table';
import Pagination from '../../../../widgets/pagination';
import { equipmentConfigurationColumns } from './partials/columns';
import { reportServices } from '../../../../service/reportServices';

interface ActiveAirSamplingReportProps {
  ishide?: boolean;
}


const EquipmentConfiguration: React.FC<ActiveAirSamplingReportProps> = ({ ishide }) => {  

  type Filters = {
    location: string;
    batchCode: string;
    lotCode: string;
    plateSerialNumber: string;
    fromDate: string;
    toDate: string;
  };
  type OptionType = {
    value: string;
    label: string;
  };

 const { t } = useTranslation()
 const [filters, setFilters] = useState({
    location: '',
    batchCode: '',
    lotCode: '',
    plateSerialNumber: '',
    fromDate: '',
    toDate: '',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [equipmentList, setEquipmentList] = useState<Array<any>>([]); 
  const [showDownloadCSV, setShowDownloadCSV] = useState(true);
  const [isApplyClicked, setIsApplyClicked] = useState(true);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);

  const handleChange = (
    eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
    actionOrUndefined?: ActionMeta<OptionType>
  ) => {
    let newFilters: Filters;
    console.log(eventOrValue, "1212")
    console.log(actionOrUndefined, "1212")
  
    if (actionOrUndefined) {
      // For react-select
      const { name } = actionOrUndefined as { name: string };
      newFilters = { ...filters, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
    } else {
      // For native select and input elements
      const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
      console.log(name, value , "12123")
      newFilters = { ...filters, [name]: value };
    }
  
    setFilters(newFilters);
  
    // Enable button if any filter is changed
    const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
    setIsButtonDisabled(!anyFilterChanged);
    setIsApplyClicked(false)
    setShowDownloadCSV(true)
  };
  
const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/equipment?equipment_id=${filters.location}&from_date=${filters.fromDate}&to_date=${filters.toDate}`
    window.location.href = link;
    setShowDownloadCSV(true)
    setIsApplyClicked(true)
};

  const listEquipments = async () => {
    try {
      const response = await equipments.listEquipmentFilter("");
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              console.log(response.data, "data---")
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    id : element.id,
                    name: element.name,
                };
              });
              setEquipmentList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else{
        setEquipmentList([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }

  //api call to list equipment configuration report
  const getEquipmentConfigurationPlateReport = async () => {
    try {
      const response = await reportServices.equipmentConfigurationReport(page, pageSize, filters.fromDate, filters.toDate, filters.location);
        if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages)
                setTotalElements(response?.totalElements)
                let rowData: any = response.data.map((element: any, index: number) => {
    
                const statusNumber = parseInt(element.location_type, 10); 
                return { 
                  id : element.id,
                  slno: index + 1,
                  name: element.equipment,
                  equipment_code: element.code,
                  startDate : element.calibration_date,
                  endDate : element.calibration_due_date,
                  min_temp : element.min_temp,
                  max_temp : element.max_temp,
                };
                });
                setPlateDetails(rowData);
            }
            } catch (error) {
            console.log(error); 
            }
        } else {
        setPlateDetails([])
        setTotalPages(0)
        setTotalElements(0)
        }
    } catch (error) {
        console.error('Plate response', error);
    }
    };

  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

  const handleApplyClick =() =>{
    setShowDownloadCSV(false)
    setIsApplyClicked(true)
    getEquipmentConfigurationPlateReport();
    // listAuditTrialReport();
  }

  const handleCancel = () => {
    const initialFormData = {
      location: '',
      batchCode: '',
      lotCode: '',
      plateSerialNumber: '',
      fromDate: '',
      toDate: '',
    };
    setFilters(initialFormData);
    setUseEffectRequired(true)
    // listAuditTrialReport();
}

  useEffect(() =>{
    listEquipments();
    getEquipmentConfigurationPlateReport();

    setUseEffectRequired(false);
  }, [useEffectRequired])

  return (
    <div>
      {!ishide && (
        <>
      <HeaderSection />
      <PageHeader pageHeader="Equipment Configuration Report" />
      </>
      )}
      <div className="filter-component">
        <div className="filter-row">
          <label className="filter-label">
            Equipment
            <select name="location" value={filters.location} onChange={handleChange} className="filter-select" style={{"height": "35px"}}>
              <option value="">Select Equipment</option>
              {equipmentList.map((type) => (
                <option key={type.id} value={type.id}>
                    {type.name}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* <div className="filter-row">
        <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateBatches}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Batch code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="batchCode"
          />
        </div>        
      </div> */}

      {/* <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotcode}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="lotCode"
          />
        </div>        
      </div> */}

      {/* <div className="filter-row">
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Plate Serial Number</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={plateSerielNum}
            onChange={(selectedOption, actionMeta) => handleChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Plate Serial Number"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="plateSerialNumber"
          />
        </div>        
      </div> */}

      <div className="filter-row">
        <label className="filter-label">
          From Date
          <input type="date" name="fromDate" value={filters.fromDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      <div className="filter-row">
        <label className="filter-label">
          To Date
          <input type="date" name="toDate" value={filters.toDate} onChange={handleChange} className="filter-input" />
        </label>
      </div>

      {/* <div className="filter-row">
        <button className="filter-button" disabled={isButtonDisabled} onClick={downloadAuditCSV}>Download Equipment Report</button>
      </div> */}

      </div>
      <div className="mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={handleCancel} >{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={downloadAuditCSV}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>

        <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
            data={plateDetails}
            columns={equipmentConfigurationColumns}
            isEditable={false}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            tableHead={'Equipment Configuration Report'}
            showBatchFilter={false}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>

      <div className="filter-row" style={{marginTop: "15%"}}></div>
    </div>
  )
}

export default EquipmentConfiguration;