import React, { useEffect, useState } from 'react'
import { IncidentOrDeviationApi } from '../../../service/deviation';
import { areaService } from '../../../service/areaServices';
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomTable from '../../../widgets/table';
import Pagination from '../../../widgets/pagination';
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../service';
import { visualInspection } from '../../../service/visualinspection';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { userService } from '../../../service/plateServices';
import {userService as userRoleServive} from '../../../service/userService';
import { reportServices } from '../../../service/reportServices';
interface TableColumn {
    Header: string;
    accessor: string;
}

type Filters = {
  operator: string;
  batchCode: string;
  lotCode: string;
  fromDate: string;
  toDate: string;
};
type OptionType = {
  value: string;
  label: string;
};
export const columns: TableColumn[] = [
  {
      Header: 'Sl.No',
      accessor: 'slno',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'Lot Code ',
      accessor: 'lot_code',
    },
    {
      Header: 'Passed Plates',
      accessor: 'passed_plates',
    },
    {
      Header: 'Operator Name',
      accessor: 'first_name',
    },
    {
      Header: 'Operator ID',
      accessor: 'user_id',
    },
    {
      Header: 'Date',
      accessor: 'inspected_on',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Inspection Reason',
      accessor: 'reason',
    },
  
];

interface ActiveAirSamplingReportProps {
  ishide?: boolean;
}


const VisualInspection: React.FC<ActiveAirSamplingReportProps> = ({ ishide }) => {  

    const { t } = useTranslation()
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [keyWord, setkeyWord] = useState("")
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [batchCode, setBatchCode] = useState("");
    const [lotCode, setLotCode] = useState([]);
    const [showDownloadCSV, setShowDownloadCSV] = useState(true);
    const [isApplyClicked, setIsApplyClicked] = useState(true);
    const [addFilterData, setAddFilterData] = useState({
      batchCode: "",     
      lotCode : "",
      operator:"",
      fromDate: "",
      toDate: "",
    });
    const [plateBatches, setPlateBatches] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userData , setUserData] = useState<Array<any>>([]); 

//api call to list exception plate detail in request tab 
const getExceptionPlates = async () => {
try {
  const response = await reportServices.visualInspectionReport(page, pageSize, addFilterData.fromDate, addFilterData.toDate, addFilterData.operator, addFilterData.batchCode, addFilterData.lotCode);

  if(response.status && response.statusCode === 200){
    try {
        if ( Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages)
            setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

            const statusNumber = parseInt(element.location_type, 10); 
            return { 
              id : element.id,
              slno: index + 1,
              batch_number: element.batch_code,
              lot_code: element.lot_code,
              passed_plates : element.passed_plates,
              first_name : element.operator_name,
              user_id : element.operator_id,
              inspected_on : element.date,
              status : element.status,
              reason : element.reason,
            };
            });
            setPlateDetails(rowData);
        }
        } catch (error) {
        console.log(error); 
        }
    } else {
    setPlateDetails([])
    setTotalPages(0)
    setTotalElements(0)
    }
} catch (error) {
    console.error('Plate response', error);
}
};

const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage);
    if(pageSize) {
      setPageSize(pageSize)
    }
    setUseEffectRequired(true)
  };

    useEffect(() => {
        getExceptionPlates();
        listPlateBatch();
        getOperatorList();
        setUseEffectRequired(false)
    }, [useEffectRequired])

    const handleApplyClick =() =>{
        setShowDownloadCSV(false)
        setIsApplyClicked(true)
        getExceptionPlates()
        // listAuditTrialReport();
    }

    const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
  }

  const lotCodeApi = async (batchNo: string) => {
    try {
      const response = await visualInspection.listFilterLots(batchNo);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    value: element.lot_code,
                    label: element.lot_code ,
                };
              });
              setLotCode(rowData);
            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

    const handleFilterDataChange = (
      eventOrValue: React.ChangeEvent<HTMLSelectElement | HTMLInputElement> | SingleValue<OptionType>,
      actionOrUndefined?: ActionMeta<OptionType>
    ) => {
      let newFilters: Filters;
    
      if (actionOrUndefined) {
        // For react-select
        const { name } = actionOrUndefined as { name: string };
        if (name === "batchCode") {
          lotCodeApi((eventOrValue as SingleValue<OptionType>)?.value || '');
        }
        newFilters = { ...addFilterData, [name]: (eventOrValue as SingleValue<OptionType>)?.value || '' };
      } else {
        // For native select and input elements
        const { name, value } = (eventOrValue as React.ChangeEvent<HTMLSelectElement | HTMLInputElement>).target;
        newFilters = { ...addFilterData, [name]: value };
      }
    
      setAddFilterData(newFilters);
    
      // Enable button if any filter is changed
      const anyFilterChanged = Object.values(newFilters).some(val => val !== '');
      setIsButtonDisabled(!anyFilterChanged);
      
        setShowDownloadCSV(true)
        setIsApplyClicked(false)
        // const { name, value } = e.target;
        // setAddFilterData((prevFormData : any) => ({
        //     ...prevFormData, 
        //     [name]: value,
        // }));
    };

    const handleCancel = () => {
      const initialFormData = {
      batchCode: "",     
      lotCode : "",
      operator:"",
      fromDate: "",
      toDate: "",
      };
      setAddFilterData(initialFormData);
      setUseEffectRequired(true)
      // listAuditTrialReport();
  }

  const downloadAuditCSV = async () => {
    const link = `${baseUrl}/summary/visual_inspection?batch_code=${addFilterData.batchCode}&lot_id=${addFilterData.lotCode}&operator_id=${addFilterData.operator}&from_date_time=${addFilterData.fromDate}&to_date_time=${addFilterData.toDate}`
    window.location.href = link;
    setShowDownloadCSV(true)
    setIsApplyClicked(true)
  };
    
  // function to get operator api list
const getOperatorList = async () => {
  try {
    // const userRole = parseInt(addFilterData.role, 10);  
    const response = await userRoleServive.getUserNameList(1);
    if(response.status && response.statusCode === 200){
      try {
          if ( Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages)
            // setTotalElements(response?.totalElements)
            let rowData: any = response.data.map((element: any, index: number) => {

              return { 
                  slno: index + 1, 
                  id: element.id,
                  name: `${element.first_name} ${element.last_name}`,
              };
            });
            setUserData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
    } else {
      setUserData([]);
    }
  } catch (error) {
    console.error(error);
  }
};

  return (
    <div>
       {!ishide && (
        <>
       <HeaderSection />
       <PageHeader pageHeader="Visual Inspection Report" />
       </>
      )}
       <div className="d-flex justify-content-around my-4">

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
            <div style={{ zIndex: '10' }}>
              <Select
                options={plateBatches}
                onChange={(selectedOption, actionMeta) => handleFilterDataChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
                placeholder="Batch code"
                isSearchable={true}
                isClearable
                // value={plateBatches.find(option => option.value === filters.batchCode) || null}
                name="batchCode"
              />
            </div>        
        </div>

      <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
      <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
        <div style={{ zIndex: '10' }}>
          <Select
            options={lotCode}
            onChange={(selectedOption, actionMeta) => handleFilterDataChange(selectedOption as SingleValue<OptionType>, actionMeta as ActionMeta<OptionType>)}
            placeholder="Lot Code"
            isSearchable={true}
            isClearable
            // value={plateBatches.find(option => option.value === filters.batchCode) || null}
            name="lotCode"
          />
        </div>        
      </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Operator</label>
        <select name="operator" className='areaTypeSelect' value={addFilterData.operator}  onChange={handleFilterDataChange}  >
            <option value="">Select</option>
            {userData.map((type, index) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
            ))}
        </select>
        </div>

        <div className="d-flex" style={{marginRight: "4%"}} >

        <div className="select-container auditTrialReportDate " 
            style={{display: "flex", flexDirection: "column", marginRight:"15%"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.from_date")}</label>
            <input
            id="selectedDate"
            className="form-control"
            name="fromDate"
            type="datetime-local"
            value={addFilterData.fromDate}  
            onChange={handleFilterDataChange} 
        />
        </div>

        <div className="select-container auditTrialReportDate" 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t("common.to_date")}</label>
            <input
            id="selectedDate"
            className="form-control"
            name="toDate"
            type="datetime-local"
            value={addFilterData.toDate}  
            onChange={handleFilterDataChange}
            />
        </div>
        </div>
        </div>

        <div className="mt-3 mx-2 d-flex justify-content-end" >
            <button className="analyticalFilterClose" title="Click here to Reset Filter" onClick={handleCancel}>{t("buttons.cancel")}</button>
            <button 
            className={isApplyClicked ? "analyticalFilterApplyDisabledDownload" : "analyticalFilterApply"}
            disabled={isApplyClicked} 
            onClick={handleApplyClick} 
            title="Click here to Apply Filter" >
                {t("buttons.apply")}
            </button>
            <button 
            className={showDownloadCSV ? "analyticalFilterDisabledDownload" : "analyticalFilterDownload"}
            disabled={showDownloadCSV} 
            onClick={downloadAuditCSV}
            title="Click here to Download CSV" >
            {t("buttons.download_pdf")}
            </button>

        </div>



       <div className=" mx-3 pe-2" style={{"height":"45vh"}}>
          <CustomTable
          data={plateDetails}
          columns={columns}
          isEditable={false}
          totalElements={totalElements}
          isActionVisible={false}
          isViewVisible={false}
          isSeachable={false}
          tableHead={'Visual Inspection'}
          showBatchFilter={false}
        />
      </div>
      <div>
        <Pagination
          page={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default VisualInspection
