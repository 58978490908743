export const columns: TableColumn[] = [
    {
        Header: "Sl no",
        accessor: "slno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Date",
        accessor: "date",
      },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }