import React, { useEffect, useState } from 'react'
import PageHeader from '../../../common/pageHeader'
import HeaderSection from '../../../common/headerSection'
import { Modal} from 'react-bootstrap'
import CustomTable from '../../../../widgets/table'
import { columns} from './partials/columns'
import { TfiClose } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next';
import AlertModal from '../../../../widgets/alertModal'
import CustomToast from '../../../../widgets/toaster'
import Pagination from '../../../../widgets/pagination';
import { userService } from '../../../../service/userService'
import { deviceRegistration } from '../../../../service/deviceRegistration'
import DateRangeModal from './partials/weekOffModal'



const HolidayRegister = () => {

  const { t } = useTranslation();


  const [showAddModal, setShowAddModal] = useState(false);
  const [modalName, setModalName] = useState(t('applicationConfiguration.addDeviceRegistration'));
  const [modalEdit, setModalEdit] = useState(false);
  const [holidayRegister, setHolidayRegister] = useState({
    dropDown: "",
    date: "",
    id: "",
  });
  const [deviceRegister, setDeviceRegister] = useState([]);
  const [addHolidayAlert, setAddHolidayAlert] = useState(false);
  const [useEffectRequired, setuseEffectRequired] = useState(false)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
   // table pagination for sample type
   const [page, setPage] = useState(1);
   const [pageSize, setPageSize] = useState(5);
   const [totalPages, setTotalPages] = useState(0);
   const [totalElements, setTotalElements] = useState(0);
   // table pagination for sample Sub type
   interface Errors {
    dropDown?: string,
    date?: string,
  }
  
  const [errors, setErrors] = useState<Errors>({});
  const [permission, setPermission] = useState<any>([]);
  const [HolidayOptionsList, setHolidayOptionsList] = useState<Array<any>>([]);
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  // const maxDate = `${currentYear}-12-31`;
  const [keyWord, setkeyWord] = useState(currentYear)
  const [showModal, setShowModal] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [newName, setNewName] = useState('');
  const handleAddNewClick = () => {
    setShowAddNew(true);
  };

  const handleSaveNewName = async () => {
    const dataToSend = {   
        name : newName, 
    }
    try {
        const response = await deviceRegistration.AddHoliday(dataToSend);
        if(response.status && response.statusCode === 200){
          setToastType("success")
          setToastMessage(response.message)
          setShowAddNew(false);
          setNewName('')
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowToast(true)
        setuseEffectRequired(true)
      } catch (error) {
        console.error('user response', error);
      }
    
  };

  useEffect(() => {

    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listHoliday();
    getMasterDropDown();
    setuseEffectRequired(false)
  }, [useEffectRequired])
  

  //get permission by role 
  const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }

  //get all_raw_material_type list from api  
  const listHoliday = async () => {
    try {
      const response = await deviceRegistration.listHolidays(page, pageSize, keyWord);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              setTotalPages(response?.totalPages)
              setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                    slno: index + 1, 
                    name: element.name,
                    id : element.id,
                    date : element.date,
                };
              });
              setDeviceRegister(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }else {
        setDeviceRegister([]);
      }
    } catch (error) {
      console.error('user response', error);
    }
  }


  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    setHolidayRegister((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  //create raw material type 
  const createHoliday = async () => {
    setAddHolidayAlert(false)
    const dataToSend = {   
        holiday_master_id : holidayRegister.dropDown,
        date : holidayRegister.date,   
    }
    try {
      const response = await deviceRegistration.SubmitHoliday(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        addModalCloseEvent()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setuseEffectRequired(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  //handle policy management alert
  const handleAddAlert = () => {
    const errors: Errors = {};
      if (!holidayRegister.dropDown) {
        errors.dropDown = "Please Enter Drop Down";
      }
      if (!holidayRegister.date) {
        errors.date = "Please Enter Date";
      }
      
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        console.log(errors)
        return;
      }
      setAddHolidayAlert(true)
      setErrors({});
  }


  const handleSubmit = () => {
    setAddHolidayAlert(false)
    createHoliday()
  }

  const addHolidayClick = () => {
    setModalName(t('applicationConfiguration.addHolidayRegistration'))
    setModalEdit(false)
    setShowAddModal(true)
  }

  const addModalCloseEvent = () => {
    setShowAddModal(false)
    setHolidayRegister({
    dropDown: "",
    date: "",
    id: "",
    })
    setErrors({})
    setShowAddNew(false);
  }

  //handle table pagination 
  const handlePageChange = (newPage : any, pageSize?: number) => {
    setPage(newPage)
    if(pageSize) {
      setPageSize(pageSize)
    }
    setuseEffectRequired(true)
  };

  //handle filter section in sample type
  const handleFilter = (key: string, value: any) => {
    if(key === "Role") {
      // setRole(value)
    } else if (key === "search") {
      setkeyWord(value)
    }
    setuseEffectRequired(true)
  }

  const getMasterDropDown = async () => {
    try {
      const response = await deviceRegistration.getMasterDropDown();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.name,
                  id: element.id,
                  status: element.status,
                };
              }
            );
            setHolidayOptionsList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('applicationConfiguration.holidayRegistration')} />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
   
        <div className='me-3' style={{marginTop : "20px"}}>
            <div className=' mx-3 pe-2' style={{"height":"45vh"}}>
                <CustomTable 
                    tableHead={t('applicationConfiguration.holidayRegistration')}
                    data={deviceRegister} 
                    columns={columns} 
                    isEditable={false} 
                    isTempDeletable={false}
                    totalElements={totalElements} 
                    isActionVisible={false} 
                    isViewVisible={false} 
                    isSeachable={true}
                    isToggleStatus={false}
                    isResetPassword={false}
                    addButton={permission?.includes("add_device")}
                    addButtonText={t('applicationConfiguration.addHolidayRegistration')}
                    onUseEffectRequired={() => setuseEffectRequired(true)}
                    onFilterClick={handleFilter}
                    onAddClick={addHolidayClick}             
                    changeRoleButton={false}
                    showBlueBtn={permission?.includes("add_device")}
                    blueBtnText='Add Week Holiday'
                    onBlueBtnClick={handleOpenModal}
                />
            </div>
            <div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
        </div>
      
        <DateRangeModal show={showModal} handleClose={handleCloseModal} />

        <Modal 
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
              <div className="modalHead">
                  <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                  <div onClick={() => addModalCloseEvent()} className="p-2 mx-3 my-2 ">
                    <TfiClose fill="#FFFFFF" />
                  </div>
              </div>

              <div>
                <div className="selectWrap">
                        <div className="form-group my-1">
                            <label htmlFor="">
                            Select Holiday{" "}
                            <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <select
                            id="dropDown"
                            value={holidayRegister.dropDown}
                            name="dropDown"
                            onChange={handleInputChange}
                            >
                            <option value="">--Select--</option>
                            {HolidayOptionsList.map((obj, index) => (
                                <option key={index} value={obj?.id}>
                                {obj?.title}
                                </option>
                            ))}
                            </select>
                            {errors.dropDown && <p className="errorText">{errors.dropDown}</p>}
                        </div>

                        <div className="form-group my-1">
                        <label htmlFor="" >Date<span style={{ color: 'red' }}>*</span></label>
                        <br />
                        <input 
                            id="date"
                            value={holidayRegister.date}
                            name="date"
                            type='date'
                            placeholder={t('common.deviceName')}
                            className="placeholder-style"
                            onChange={handleInputChange}
                            maxLength={100}
                            min={minDate}
                            // max={maxDate}
                        />
                        {errors.date && <p className="errorText">{errors.date}</p>}
                        </div>
                </div>
                <div className="selectWrap-email">
                        <div className="form-group my-1 mt-4">
                            {!showAddNew && (
                            <p>
                               Add Holiday?{" "}
                                <span
                                onClick={handleAddNewClick}
                                style={{ cursor: 'pointer', color: 'blue', textDecoration: "underline" }}
                                >
                                click here
                                </span>
                            </p>
                            )}
                            {showAddNew && (
                            <div>
                                <input
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                placeholder="Enter New Name"
                                className="placeholder-style"
                                />
                                <button className="squareOrangeBtn3 mx-3" onClick={handleSaveNewName}>Save</button>
                            </div>
                            )}
                        </div>
                    </div>

              </div>
                <div className="bottomArea">
                  <button onClick={() => addModalCloseEvent()} className="modalCancelBtn">{t('buttons.cancel')}</button>
                  <button onClick={() => handleAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
                </div>
            </div>
          
        </Modal>

        {/* Alert modal for Add policy */}
        <AlertModal 
          show={addHolidayAlert}
          onHide={() => setAddHolidayAlert(false)}
          title='Alert'
          message={`${modalName}`}
          onConfirm={handleSubmit}
        />

    </div>
  )
}

export default HolidayRegister