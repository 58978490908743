import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";

let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );

const listAreaType= async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=100&sort=id&order=asc&status=active&keyword=&type=6`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listLocationType= async (parent_area: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/location/list_all?page=1&size=&sort=id&order=asc&status=active&keyword=&type=11,15,10,13&parent_area=${parent_area}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  const listTypeOptions =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_organism_types?sort=organism_type&order=asc&status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }
  
  const listGenusOptions =  async (type_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_colony_genus?sort=organism_type&order=asc&status=active&organism_type_id=${type_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }
  
  const listSpeciesOptions =  async (type_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_colony_species?sort=organism_type&order=asc&status=active&genus_id=${type_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }
  
  const listStrainOptions =  async (type_id: string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_colony_strain?sort=organism_type&order=asc&status=active&species_id=${type_id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listShiftOptions =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_shifts?sort=name&order=asc&status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listSeriesTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master/list_series_type?sort=organism_type&order=asc&status=active&species_id=1`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listActivityTypes =  async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master_activities/list?sort=id&order=asc`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }


  interface FormData {
    areaDropdownValue: string;
    locationDropdownValue: string;
    trendTypeSelect: string;
    activitySelect: string;
    shiftDetails: string;
    seriesSelect: string;
    organismTypeSelect: string;
    genusSelect: string;
    speciesSelect: string;
    strainSelect: string;
    graphTypeSelect: string;
    fromDate: string;
    toDate: string;
  }
  
  const getTrendAnalyticsData = async (formData: FormData) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytics_dashboard/trend_analytics?page=1&size=10&sort=id&order=asc`, {
        params: {
          area_id: formData.areaDropdownValue || '',
          location_id: formData.locationDropdownValue || '',
          graph_type: formData.trendTypeSelect || 'area',
          activity: formData.activitySelect || '',
          shift_id: formData.shiftDetails || '',
          series_type: formData.seriesSelect || 'organism_type',
          organism_type_id: formData.organismTypeSelect || '',
          genus_id: formData.genusSelect || '',
          species_id: formData.speciesSelect || '',
          strain_id: formData.strainSelect || '',
          from_date: formData.fromDate || '',
          to_date: formData.toDate || ''
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }


  interface ExcursionData {
    area: string;
    location: string;
    graph_type: string;
    activity: string;
    operator: string;
    from_date: string;
    to_date: string;
  }

  const getExcursionAnalyticsData = async (formData: ExcursionData) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/report/excursions?`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          graph_type: formData.graph_type || 'day',
          activity: formData.activity || '',
          operator_id: formData.operator || '',
          from_date: formData.from_date || '',
          to_date: formData.to_date || ''
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }
  

  const getExceptionSummaryData = async (formData: any, page: any , pageSize: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytical_dashboard/exception_summary?page=${page}&size=${pageSize}&order=desc&sort=id`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          requested_by: formData.operatorType || '',
          batch_number: formData.batchCode || '',
          serial_number: formData.serialNumber || '',
          reason_id: formData.exceptionReason|| '',
          from_date: formData.fromDate || '',
          to_date: formData.toDate || ''
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }

  const getAreaLocationOperatorSummary = async (formData: any, graphType: any , page: any , pageSize: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytical_dashboard/list_summary_report?page=${page}&size=${pageSize}&order=desc&sort=date`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          shift_id: formData.shift || '',
          activity: formData.activityType || '',
          operator_id: formData.operatorType || '',
          report_type: graphType|| '',
          from_date: formData.fromDate || '',
          to_date: formData.toDate || ''
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }


  const getScheduleSummary = async (formData: any, page: any , pageSize: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytical_dashboard/schedule_summary?page=${page}&size=${pageSize}&order=desc&sort=schedule_production_id`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          operator_id: formData.operatorType || '',
          organism_type_id: formData.organismType || '',
          genus_id: formData.organismGenus || '',
          species_id: formData.organismSpecies || '',
          strain_id: formData.organismStrain || '',
          activity_id: formData.activityType || '',        
          from_date: formData.fromDate || '',
          to_date: formData.toDate || ''
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }

  const getViolationsSummary = async (formData: any, graphTypelbel: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytical_dashboard/user_schedule_violations?order=desc&sort=schedule_production_id`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          operator_id: formData.operatorType || '',
          organism_type_id: formData.organismType || '',
          genus_id: formData.organismGenus || '',
          species_id: formData.organismSpecies || '',
          strain_id: formData.organismStrain || '',
          activity_id: formData.activityType || '',        
          from_date: formData.fromDate || '',
          to_date: formData.toDate || '', 
          graph_type: graphTypelbel || 'area'
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }


  const getExceptionViolationsSummary = async (formData: any, graphTypelbel: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/analytical_dashboard/exception_violation?order=desc&sort=id`, {
        params: {
          area_id: formData.area || '',
          location_id: formData.location || '',
          operator_id: formData.operatorType || '',
          organism_type_id: formData.organismType || '',
          genus_id: formData.organismGenus || '',
          species_id: formData.organismSpecies || '',
          strain_id: formData.organismStrain || '',
          activity_id: formData.activityType || '',        
          from_date: formData.fromDate || '',
          to_date: formData.toDate || '', 
          graph_type: graphTypelbel || 'area'
        },
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      }
      throw error;
    }
  }


const trendAnalytics = {
    listAreaType,
    listLocationType,
    listTypeOptions,
    listGenusOptions,
    listSpeciesOptions,
    listStrainOptions,
    listShiftOptions,
    getTrendAnalyticsData,
    listSeriesTypes,
    getExcursionAnalyticsData,
    listActivityTypes,
    getExceptionSummaryData,
    getAreaLocationOperatorSummary,
    getScheduleSummary,
    getViolationsSummary,
    getExceptionViolationsSummary
  };
  
  export { trendAnalytics };