export const columns: TableColumn[] = [
    {
        Header: 'Sl no',
        accessor: 'slno',
      },
      {
        Header: 'Batch Code',
        accessor: 'batch_code',
      },
      {
        Header: 'Batch GRN',
        accessor: 'batch_grn',
      },
      {
        Header: 'Manufacturing Date',
        accessor: 'manufacturing_date',
      },
      // {
      //   Header: 'Expiry Date',
      //   accessor: 'expiry_date',
      // },
      {
        Header: 'Lot Count',    
        accessor: 'lot_count',
      },
      {
        Header: 'Plate Type',    
        accessor: 'plateType',
      },
      {
        Header: 'Quantity',    
        accessor: 'quantity',
      },
      {
        Header: 'Status',    
        accessor: 'status',
      },
]

export const Lotcolumns: TableColumn[] = [
  {
      Header: 'Sl no',
      accessor: 'slno',
    },
    {
      Header: 'Lot Code',
      accessor: 'lot_code',
    },
    {
      Header: 'Batch GRN',
      accessor: 'batch_grn',
    },
    {
      Header: 'Manufacturing Date',
      accessor: 'manufacturing_date',
    },
    // {
    //   Header: 'Expiry Date',
    //   accessor: 'expiry_date',
    // },
    {
      Header: 'Lot Count',    
      accessor: 'plate_count',
    },
    {
      Header: 'Plate Type',    
      accessor: 'passed_plate_count',
    },
    {
      Header: 'Quantity',    
      accessor: 'quantity',
    },
    {
      Header: 'Status',    
      accessor: 'inspection_status',
    },
]


export const Platecolumns: TableColumn[] = [
  {
      Header: 'Sl no',
      accessor: 'slno',
    },
    {
      Header: 'Plate Serial No',
      accessor: 'serial_number',
    },
    {
      Header: 'Activity',
      accessor: 'activity',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    // {
    //   Header: 'Expiry Date',
    //   accessor: 'expiry_date',
    // },
    {
      Header: 'Start Date',    
      accessor: 'manufacturing_date',
    },
    {
      Header: 'End Date',    
      accessor: 'expiry_date',
    },
    {
      Header: 'Started By',    
      accessor: 'started_by',
    },
    {
      Header: 'Completed By',    
      accessor: 'completed_by',
    },
    {
      Header: 'Status',    
      accessor: 'status',
    },
]

export const BatchWiseReconcilationColums: TableColumn[] = [
  {
    Header: 'Sl no',
    accessor: 'slno',
  },
  {
    Header: 'Batch',
    accessor: 'batch_code',
  },
  {
    Header: 'Batch Lot',
    accessor: 'lot_count',
  },
  {
    Header: 'Plates Available',
    accessor: 'plates_available',
  },
  {
    Header: 'Plates Used',    
    accessor: 'plates_used',
  },
  {
    Header: 'Remaining Plates',    
    accessor: 'remaining_plates',
  },
]

export const LotWiseReconcilationColums: TableColumn[] = [
  {
    Header: 'Sl no',
    accessor: 'slno',
  },
  {
    Header: 'Lot Details',
    accessor: 'lot_code',
  },
  {
    Header: 'Plates Available',
    accessor: 'plates_availables',
  },
  {
    Header: 'Plates Used',    
    accessor: 'plates_used',
  },
  {
    Header: 'Remaining Plates',    
    accessor: 'remaining_plates',
  },
]

export const ActivityWiseReconcilationColums: TableColumn[] = [
  {
    Header: 'Sl no',
    accessor: 'slno',
  },
  {
    Header: 'Lot Details',
    accessor: 'lot_code',
  },
  {
    Header: 'Plate Sampling',
    accessor: 'plate_sampling',
  },
  {
    Header: 'Active Air Sampling',
    accessor: 'air_sampling',
  },
  {
    Header: 'Passive Air Sampling',    
    accessor: 'passive_sampling',
  },
  {
    Header: 'Sample Type Test',    
    accessor: 'sample_type_test',
  },
  {
    Header: 'Surface Monitoring',    
    accessor: 'surface_monitoring',
  },
  {
    Header: 'Personnel Monitoring',    
    accessor: 'personnel_monitoring',
  },
  {
    Header: 'Surface Monitoring- Swab Collection',    
    accessor: 'swab_collection',
  },
  {
    Header: 'Visual Inspection Failed',    
    accessor: 'visual_inspection_failed',
  },
  {
    Header: 'Plate Rejected',    
    accessor: 'plate_rejected',
  },
  {
    Header: 'Plate Discarded',    
    accessor: 'plate_discarded',
  },
  {
    Header: 'Exception Added',    
    accessor: 'exception_added',
  },
]

export const PlateWiseReconcilationColums: TableColumn[] = [
  {
    Header: 'Sl no',
    accessor: 'slno',
  },
  {
    Header: 'Plate Details',
    accessor: 'serial_number',
  },
  {
    Header: 'Plate Status',    
    accessor: 'plateStatus',
  },
]


interface TableColumn {
    Header: string;
    accessor: string;
  }
