import React, { useState, useRef, useEffect } from 'react';
import { TfiClose } from 'react-icons/tfi';
import Webcam from 'react-webcam';
import { FileService } from '../../../../service/fileServices';
import html2canvas from 'html2canvas';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useTranslation } from 'react-i18next';
import baseUrl from '../../../../service';
import { Alert, ToastHeader } from 'react-bootstrap';
import CustomToast from '../../../../widgets/toaster';



interface CameraAppProps {
  setColonyCount: (value: any) => void;
  setImageId: (value: string | null) => void;
  setImageIds: (value : any) => void;
  imageIds: string[];
  countAuto: boolean;
  spots: any[];
  setSpots: (value: any | null) => void;
  isCameraOpen: boolean;
  setIsCameraOpen: (value: boolean) => void;
  capturedImage: string;
  setCapturedImage: React.Dispatch<React.SetStateAction<any | null>>;

}

const CameraApp: React.FC<CameraAppProps> = ({
    setColonyCount, 
    setImageId, 
    imageIds,
    setImageIds,
    countAuto,
    setSpots,
    spots,
    isCameraOpen,
    capturedImage,
    setIsCameraOpen,
    setCapturedImage
  }) => {
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();
   const [showCamera, setShowCamera] = useState(false);
   const [isImgConfirmed, setIsImgConfirmed] = useState(false);
   const screenshotRef = useRef(null);
  //  const [imageIds, setImageIds] = useState<any>([])
   const [showToast, setShowToast] = useState(false);
   const [toastMessage, setToastMessage] = useState("");
   const [toastType, setToastType] = useState("");
   const [zoomLevel, setZoomLevel] = React.useState(1);
   const [selectedSpotType, setSelectedSpotType] = useState('B');



  //capture image 
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
    }
    setIsImgConfirmed(false);
  };

  //retake image if necessary
  const retakeImage = () => {
    setCapturedImage(null);
    setShowCamera(true);
    setSpots([])
    setImageId("")
    if(imageIds.length < 1) {
      setColonyCount("")
    }
  };

  //set the width and height of the image frame 
  const videoConstraints = {
    width: 600,
    height: 250,
  };

  const containerStyle: React.CSSProperties = {
    width: `${videoConstraints.width}px`,
    height: `${videoConstraints.height}px`,
    backgroundColor: 'gray',
    position: 'relative',
    color: 'white',
  };

  //capture the colony count on screen click 
  const handleDotClick = (e : any) => {
    if (e.target.classList.contains('capturedPlateBg') && imageIds.length < 1) {
      const { offsetX, offsetY } = e.nativeEvent;
      // const newDot = { x: offsetX, y: offsetY };
      const newDot = { x: offsetX, y: offsetY};
      setSpots([...spots, newDot]);
      setColonyCount((spots.length + 1).toString());
    } else if (e.target.classList.contains('spot')) {
    }
   
  };

  //remove the captured count on double click 
  const handleDotRemove = (spot: any) => {
    if(!isImgConfirmed){
      const updatedDots = spots.filter((d : any) => d !== spot);
      setSpots(updatedDots);
      setColonyCount((spots.length - 1).toString());
    }
  };

  //click on confirm button will pass the image to database 
  const handleConfirm = async () => {
    const webcamElement = screenshotRef.current;
    if (webcamElement) {
      try {
        const canvas = await html2canvas(webcamElement);
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/jpeg");
        // link.download = "screenshot.jpeg";
        // link.click();
    
        const response = await FileService.uploadImage(link.href);
        
        if (response.status && response.statusCode === 200) {
          // setImageId(response.file_id);
          setImageIds((prevImages : any) => [...prevImages, response.file_id])
          setIsImgConfirmed(true);
          setToastMessage(response.message)
          setShowToast(true)
          setToastType("success")
        } else {
          setToastMessage(response.message)
          setShowToast(true)
          setToastType("error")
          console.error('Error uploading image:', response);
        }
      } catch (error) {
        console.error('Error capturing and uploading image:', error);
      }
    } else {
      console.error("Unable to access the video element from webcamRef.current");
    }
    
  }

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1); // Increase zoom level
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1); // Decrease zoom level
  };

  const handleCameraClose = () => {
    setIsCameraOpen(false)
    setImageIds([])
  }

  // const handleSelectedSpotChange = (event : any) => {
  //   setSelectedSpotType(event.target.value);
  // };

  return (
    <div className='d-flex'>
      <div>
    {capturedImage && countAuto && (
      <div>

          {/* <div className='d-flex align-items-center m-1'>
          <div className="form-check me-3">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault1"
              id="flexRadioDefault1"
              value="B"
              checked={selectedSpotType === 'B'}
              onChange={handleSelectedSpotChange}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Bacteria
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault2"
              id="flexRadioDefault2"
              value="F"
              checked={selectedSpotType === 'F'}
              onChange={handleSelectedSpotChange}
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              Fungus
            </label>
          </div>
          </div> */}

       <div style={containerStyle}>
        {/* <img src={capturedImage} alt="Captured" /> */}
        <TransformWrapper
          // defaultScale={1}
          // defaultPositionX={0}
          // defaultPositionY={0}
          // options={{ limitToBounds: false }}
          // wheel={{ step: 100 }}
          // doubleClick={{ disabled: true }}
          // pinch={{ disabled: false }}
        >
          {({ zoomIn, zoomOut }) => (
            <>
        <TransformComponent>
        <div 
          ref={screenshotRef}
          // className={isImgConfirmed ? "capturedPlateBg" : "scannedPlateBg"} 
          className={isImgConfirmed ? "scannedPlateBg" : "capturedPlateBg"} 
          style={{ backgroundImage: `url(${capturedImage} )`, backgroundSize: 'contain', transform: `scale(${zoomLevel})`}} 
          onClick={handleDotClick}
        > {spots.length < 1 && imageIds.length < 1 && (
            <span style={{"position":"absolute", "left": "50%", "top": "50%", "transform": "translate(-50%, -50%)"}}>{t('labtestManagement.click_colony_count')}</span>
          )}
          { spots?.map((spot: { x: number; y: number ; type:string }, index : number) => (
              <div
                  key={index}
                  className="spot"
                  style={{ left: spot.x, top: spot.y }}
                  onClick={() => handleDotRemove(spot)}
              >
                {/* <TfiClose className='mb-2' fill='red' /> */}
                {index + 1}
                {/* {index + 1}{spot?.type} */}
              </div>
            ))}
        </div>
        </TransformComponent>
        <div className=' d-flex justify-content-end'>
              <button className='zoominBtn m-1' onClick={() => zoomIn()}>Zoom In</button>
              <button className='zoominBtn m-1 px-2' onClick={handleZoomIn}>+</button>
              <button className='zoomoutBtn m-1 px-2' onClick={handleZoomOut}>-</button>
              <button className='zoomoutBtn m-1' onClick={() => zoomOut()}>Zoom Out</button>
            </div>
        </>
        )}
      </TransformWrapper>
      </div>

      

      <div> 
        {imageIds.length < 1 && (
          <button onClick={retakeImage} className='squareOrangeBtn3  m-3'>Retake</button>
        )}      
      {!isImgConfirmed && (
          <button 
            disabled={imageIds.length >= 4} 
            onClick={() => handleConfirm()} 
            style={imageIds.length >= 4 ? { opacity: '0.6' } : {}}
            className='greenBtn m-3'>{t('buttons.confirm')}</button>
      )}
    </div>
    </div>
    )}
     {!capturedImage && isCameraOpen && countAuto && (
        <div>
        <div style={containerStyle}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        /><span style={{"position":"absolute", "left": "50%", "top": "50%", "transform": "translate(-50%, -50%)"}}>{t('labtestManagement.click_camera')}</span>
        {/* <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
          <button onClick={captureImage} className='squareOrangeBtn3  m-3'>Capture</button>
          <button onClick={() => setIsCameraOpen(false)} className='modalCancelBtn2  m-3'>Close</button>
        </div> */}
      </div>
      <div>
        <button onClick={captureImage} className='squareOrangeBtn3  m-3'>{t('buttons.capture')}</button>
        <button onClick={() => handleCameraClose()} className='redBtn m-3'>{t('buttons.close')}</button>
    </div>
    </div>
    )}
    {!isCameraOpen && countAuto && (
      <button className='modalCancelBtn2' onClick={() => setIsCameraOpen(true)}>{t('labtestManagement.open_camera')}</button>
    )}
    </div>
    <div>
        {isCameraOpen && countAuto && imageIds.map((obj: any) =>(
          <div className='m-1 p-1' onClick={ () => setCapturedImage(`${baseUrl}/files/download?file_id=${obj || ""}`) }>
            {/* <img src={obj} width={100} height={60} alt="Captured" /> */}
            <img
              src={`${baseUrl}/files/download?file_id=${obj || ""}`}
              alt="Plate Image"
              width={100}
              height={50}
            />
          </div>
        ))}
        {isCameraOpen && countAuto && imageIds.length < 4 && imageIds.length >= 1 && (
          <div className='d-flex justify-content-center'>
            <button className='addImageBtn' onClick={retakeImage}>+</button>
          </div>
        )}
      </div>
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />
    </div>

  );
};

export default CameraApp;