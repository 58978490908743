import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import CustomToast from "../../../../../widgets/toaster";
import AlertModal from "../../../../../widgets/alertModal";
import { deviceRegistration } from "../../../../../service/deviceRegistration";
import Select from 'react-select';

interface DateRangeModalProps {
  show: boolean;
  handleClose: () => void;
}

const DateRangeModal: React.FC<DateRangeModalProps> = ({
  show,
  handleClose,
}) => {
  const [selectedDays, setSelectedDays] = useState<{ value: string; label: string }[]>([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const currentYear = new Date().getFullYear();
  const minDate = `${currentYear}-01-01`;
  const maxDate = `${currentYear}-12-31`;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [addHolidayAlert, setAddHolidayAlert] = useState(false);

  const handleDaysChange = (selectedOptions: any) => {
    setSelectedDays(selectedOptions || []);
  };

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };

  const handleSubmit = () => {
    setAddHolidayAlert(true)
  };

  const handleSubmitWeekDays = async () =>{
    const weekDayString = selectedDays.map(d => d.value).join(',');
    setAddHolidayAlert(false)
    const dataToSend = {   
        week_day : weekDayString,
        from_date : fromDate,
        to_date : toDate   
    }
    try {
      const response = await deviceRegistration.AddWeekHoliday(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        handleClose()
      } else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
    } catch (error) {
      console.error('user response', error);
    }
  }

  useEffect(() => {
    if (!show) {
      setSelectedDays([]);
      setFromDate('');
      setToDate('');
    }
  }, [show]);
   
  const daysOptions = [
    { value: '2', label: 'Monday' },
    { value: '3', label: 'Tuesday' },
    { value: '4', label: 'Wednesday' },
    { value: '5', label: 'Thursday' },
    { value: '6', label: 'Friday' },
    { value: '7', label: 'Saturday' },
    { value: '1', label: 'Sunday' },
  ];

  return (
    <>
     <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 

    <Modal className="top-right-modal-md" centered={false} show={show}>
      <div>
        <div className="modalHead">
          <p className="modalHeadText ms-3 pt-3 p-2">Add Week Holidays</p>
          <div onClick={() => handleClose()} className="p-2 mx-3 my-2 ">
            <TfiClose fill="#FFFFFF" />
          </div>
        </div>

        <div>
          <div className="selectWrap">
            <div className="form-group my-1">
              <label htmlFor="">
                Select Weekdays <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <Select
                isMulti
                value={selectedDays}
                onChange={handleDaysChange}
                options={daysOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select days"
            />
              {/* {errors.dropDown && <p className="errorText">{errors.dropDown}</p>} */}
            </div>

            <div className="form-group my-1">
              <label>From Date</label>
              <br/>
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="placeholder-style"
                min={minDate}
                max={maxDate}
              />
            </div>

            <div className="form-group my-1">
              <label>To Date</label>
              <br />
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="placeholder-style"
                min={minDate}
                max={maxDate}
              />
            </div>
          </div>
        </div>
        <div className="bottomArea">
          <button onClick={() => handleClose()} className="modalCancelBtn">
            Cancel
          </button>
          <button onClick={() => handleSubmit()} className="squareOrangeBtn3">
            Submit
          </button>
        </div>
      </div>
    </Modal>

    <AlertModal 
          show={addHolidayAlert}
          onHide={() => setAddHolidayAlert(false)}
          title='Alert'
          message={`Add Week Holidays`}
          onConfirm={handleSubmitWeekDays}
        />
    </>
  );
};

export default DateRangeModal;
