import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import CustomToast from "../../../../widgets/toaster";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { columns } from "./partials/columns";
import { rolesAndPermission } from "../../../../service/rolesAndPermission";
import AlertModal from "../../../../widgets/alertModal";
import { userService } from "../../../../service/userService";
import { Modal } from 'react-bootstrap';
import { TfiClose } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";



const GradeConfiguration = () => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [rolesList, setrolesList] = useState<Array<any>>([]);
  const [userId, setUserId] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [authUserID, setAuthUserID] = useState("");
  const [tab , setTab] = useState("1");
  const [editId , setEditId] = useState("");
  const [role, setRole] = useState("");
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  // const [id , setId] = useState("");
  const [useeffect , setUseEffect] = useState(false);
  const [statusAlert , setStatusAlert] = useState(false);
  const [gradeStatus, setGradeStatus] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [modalName, setModalName] = useState("");
  const [permission, setPermission] = useState<any>([]);
  const [addUserData, setAddUserData] = useState({
    gradeType:"",
    activityType: "",
    minimumCFUCount: "",
    maximumCFUCount: "",
    uom : "",
    reason : "",
    id : ""
  });
  const [showAddModal, setShowAddModal] = useState(false)
  const [addStatusAlert , setAddStatusAlert] = useState(false);
  const [activityList, setActivityList] = useState<Array<any>>([]); 
  const [uomList, setUomList] = useState<Array<any>>([]); 
  const [grade, setGrade] = useState<Array<any>>([]); 
  interface Errors {
    gradeType?: string,
    activityType?: string,
    minimumCFUCount?: string,
    maximumCFUCount?: string,
    reason?: string,
  }
  const [errors, setErrors] = useState<Errors>({});
  const [modalEdit, setModalEdit] = useState(false);

  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      setAuthToken(storedObject[0].x_auth_token);
      setAuthUserID(storedObject[0].x_userid);
      getPermissionByRole(storedObject[0].role_master_id);
    }

    listGradeTableList();
  }, []);

  const listGradeTableList = async () => {
    try {
      const response = await gradeTypes.listGradeType(page, pageSize);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,
                  activity_type: element.activity_type_name,
                  id: element.id,
                  min_count: element.min_count,
                  max_count: element.max_count,
                  status: element.status,
                  uom_name : element.uom,
                  type: element.activity_type,
                  grade_id : element.grade_id
                };
              }
            );
            setrolesList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  useEffect(() => {
    listGradeTableList();
    getActivityList();
    getUOMList();
    listGrade();
    setUseEffectRequired(false);
    setUseEffect(false)
  }, [useeffect, useEffectRequired]);


  const handleAddClick = () => {
    setShowAddModal(true)
    setModalName("Add Limits")
    setModalEdit(false)
  };

  const handleEditClick = (id: string) => {
    const filteredUserDetails = rolesList.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
    setModalName("Edit Limits")
    setModalEdit(true)
    setShowAddModal(true)
    setAddUserData({
      gradeType: filteredUserDetails[0]?.grade_id,
      activityType: filteredUserDetails[0]?.type,
      minimumCFUCount: filteredUserDetails[0]?.min_count,
      maximumCFUCount: filteredUserDetails[0]?.max_count,
      uom: filteredUserDetails[0]?.uom_name,
      reason: filteredUserDetails[0]?.reason,
      id: filteredUserDetails[0]?.id
    })
  }


  const handleStatusChange = (area_id:any , status: string) => {
    setStatusAlert(true)
    setGradeStatus(area_id)
      if (status === "active") {
        setUpdatedStatus("inactive")
      } else {
        setUpdatedStatus("active")
      }
  }

  const handleStatusChangeSubmit = async () => {
    try {
      const response = await gradeTypes.changeGradeStatus(gradeStatus, updatedStatus , "Limits Configuration Status");
      
      if (response.status && response.statusCode === 200) {
        setToastType("success")
        setToastMessage(response.message)
      }
      else {
        setToastType("error")
        setToastMessage(response.message)
      }
      setShowToast(true)
      setUseEffectRequired(true)
      setUpdatedStatus("")
      setGradeStatus("")
      setStatusAlert(false)
    } catch (error) {
      console.error(error);
    }
  }

   //get permission by role 
   const getPermissionByRole = async (role : number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if(response.status && response.statusCode === 200){
        setPermission(response.data.permissions)
      }
    } catch (error) {
      console.error(error);
    }
  }


  const handleAddUserChange = (e : any) => {
    const { name, value } = e.target;

    let regex;
    if (name === "minimumCFUCount") {
      regex = /^[0-9]{0,10}$/;
    } else if (name === "maximumCFUCount") {
      regex = /^[0-9]{0,10}$/;
    }

    if (value === "") {
      // console.log("haa122")
    } else if (regex && !regex.test(value)) {
      return
    }

    setAddUserData((prevFormData : any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const addGradeClose = () => {
    setShowAddModal(false)
    setErrors({})
    setAddUserData({
      gradeType:"",
      activityType: "",
      minimumCFUCount: "",
      maximumCFUCount: "",
      uom : "",
      reason : "",
      id : ""
    });
  }

  const handleUserAddAlert = () => {
    const errors: Errors = {};
    // if (!addUserData.gradeType) {
    //   errors.gradeType = "Please enter Grade Type";
    // }
    if (!addUserData.activityType) {
      errors.activityType = "Please select Activity Type";
    }
    if (!addUserData.minimumCFUCount) {
      errors.minimumCFUCount = "Please enter Minimum CFU Count";
    }
    if (!addUserData.maximumCFUCount) {
      errors.maximumCFUCount = "Please enter Maximum CFU Count";
    }
    if (addUserData.id && !addUserData.reason) {
      errors.reason = "Please enter reason";
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setAddStatusAlert(true)
    setErrors({});
  }

  const handleGradeSubmit = async () => {
    setAddStatusAlert(false)
    const dataToSend = {
      "grade_id" : addUserData.gradeType,
      "activity_type" : addUserData.activityType,
      "id" : addUserData.id,
      "interface_name" : "Limits Configuration",
      "min_count" : addUserData.minimumCFUCount,
      "max_count" : addUserData.maximumCFUCount,
      "uom" : addUserData.uom,
      "reason" : addUserData.reason
    }
    try {
      const response = await gradeTypes.addGradeType(dataToSend);
      if(response.status && response.statusCode === 200){
        setToastType("success")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)
        addGradeClose();
        setUseEffectRequired(true)
        setTab("1")
      } else {
        setToastType("error")
        setToastMessage(response.message)
        setShowToast(true)
        setAddStatusAlert(false)
  
      }
     
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const getActivityList = async () => {
    try {
      const response = await gradeTypes.getActivityList();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                  label: element.activity_name,
                  value : element.id,
                };
              });
              setActivityList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUOMList = async () => {
    try {
      const response = await gradeTypes.getUOMList();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {

                return { 
                  label: element.uom,
                  value : element.id,
                };
              });
              setUomList(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const listGrade = async () => {
    try {
      const response = await gradeTypes.listGradeOptions();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages);
            // setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,               
                  id: element.id,    
                };
              }
            );
            setGrade(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  
  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Limits Configuration" />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />

      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={rolesList}
            columns={columns}
            isEditable={permission?.includes("edit_grade_type")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={false}
            isSeachable={false}
            isToggleStatus={false}
            tableHead="Limits Table"
            showBatchFilter={false}
            showSerielNoFilter={false}
            addButton={permission?.includes("add_grade_type")}
            addButtonText="Add Limits"
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            onStatusChange={handleStatusChange}
            onUseEffectRequired={() => setUseEffectRequired(true)}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      <AlertModal 
        show={statusAlert}
        onHide={() => setStatusAlert(false)}
        title='Alert'
        message={`Change Grade Status`}
        onConfirm={handleStatusChangeSubmit}
        />

      <AlertModal 
        show={addStatusAlert}
        onHide={() => setAddStatusAlert(false)}
        title='Alert'
        message={`${modalName} Type`}
        onConfirm={handleGradeSubmit}
        />

        <Modal
              className="top-right-modal"
              centered={false}
              show={showAddModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div onClick={() => addGradeClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">Grade Type</label>
                    <br />
                     <select
                      id="gradeType"
                      value={addUserData.gradeType}
                      name="gradeType"
                      onChange={handleAddUserChange} 
                      disabled={modalEdit}
                      className={`placeholder-style ${modalEdit ? 'disabled-css' : ''}`}
                    >
                      <option value="">select</option>
                      {grade.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.grade}
                        </option>
                      ))}
                    </select>
                    {/* {errors.gradeType && <p className="errorText">{errors.gradeType}</p>} */}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">Activity Type<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <select
                       id="activityType"
                       value={addUserData.activityType}
                       name="activityType"
                       onChange={handleAddUserChange} 
                       disabled={modalEdit}
                       className={`placeholder-style ${modalEdit ? 'disabled-css' : ''}`}
                    >
                      <option value="">--Select--</option>
                      {activityList.map((obj, index) => (
                        <option key={index} value={obj?.value}>
                          {obj?.label}
                        </option>
                      ))}
                    </select>
                    {errors.activityType && <p className="errorText">{errors.activityType}</p>}
                  </div>
                </div>
                <div className="selectWrap">
                  <div className="form-group my-1">
                    <label htmlFor="">Minimum CFU Count<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                      id="minimumCFUCount"
                      value={addUserData.minimumCFUCount}
                      name="minimumCFUCount"
                      type='text'
                       placeholder='Minimum CFU Count'
                       className="placeholder-style"
                      onChange={handleAddUserChange} 
                    />
                    {errors.minimumCFUCount && <p className="errorText">{errors.minimumCFUCount}</p>}
                  </div>

                  <div className="form-group my-1">
                    <label htmlFor="">Maximum CFU Count<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <input 
                       id="maximumCFUCount"
                       value={addUserData.maximumCFUCount}
                       name="maximumCFUCount"
                       type='text'
                       placeholder='Maximum CFU Count'
                       className="placeholder-style"
                       onChange={handleAddUserChange} 
                    />
                    {errors.maximumCFUCount && <p className="errorText">{errors.maximumCFUCount}</p>}
                  </div>


                  

                </div>
                <div className="selectWrap">
                <div className="form-group my-1">
                    <label htmlFor="">UOM</label>
                    <br />
                    <select
                       id="uom"
                       value={addUserData.uom}
                       name="uom"
                       onChange={handleAddUserChange} 
                    >
                      <option value="">--Select--</option>
                      {uomList.map((obj, index) => (
                        <option key={index} value={obj?.value}>
                          {obj?.label}
                        </option>
                      ))}
                    </select>
                    {/* {errors.role && <p className="errorText">{errors.role}</p>} */}
                  </div>

                  {addUserData.id ? (
                  <div className="form-group my-1 ">
                      <label htmlFor="" className='ReasonLabel'>{t('userManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                          id="reason"
                          value={addUserData.reason}
                          name="reason"
                          type='text'
                          placeholder={t('userManagement.reason')}
                           className="reasonClass"
                          onChange={handleAddUserChange} 
                      />
                      {errors.reason && <p className="errorText">{errors.reason}</p>}
                  </div>
                  ) : (
                    <div className="form-group my-1" style={{"visibility" : "hidden"}}>
                      <label htmlFor="">{t('userManagement.department')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="department"
                        // value={addUserData.department}
                        name="department"
                        // onChange={handleAddUserChange} 
                      >
                        <option value="">--Select--</option>
                        {/* {departmentList.map((obj, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.department_name}
                          </option>
                        ))} */}
                      </select>
                  </div>
                  )}

                </div>
              </div>
              <div className="bottomArea">
            <button onClick={() => addGradeClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handleUserAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
        </Modal>
    </div>
  );
};

export default GradeConfiguration;
