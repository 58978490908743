export const columns: TableColumn[] = [
    {
        Header: 'tableCommon.sl_no',
        accessor: 'slno',
      },
      {
        Header: 'tableCommon.batch_code',
        accessor: 'batch_code',
      },
      {
        Header: 'tableCommon.batchGRN',
        accessor: 'batch_grn',
      },
      {
        Header: 'tableCommon.manufacturingDate',
        accessor: 'manufacturing_date',
      },
      {
        Header: 'tableCommon.lot_count',    
        accessor: 'lot_count',
      },
      {
        Header: 'tableCommon.plateType',    
        accessor: 'plateType',
      },
      {
        Header: 'tableCommon.plateCount',    
        accessor: 'quantity',
      },
      {
        Header: 'tableCommon.status',    
        accessor: 'status',
      },
]

export const Lotcolumns: TableColumn[] = [
  {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'tableCommon.lot_count',    
      accessor: 'plate_count',
    },
    {
      Header: 'tableCommon.status',    
      accessor: 'inspection_status',
    },
]


export const Platecolumns: TableColumn[] = [
  {
      Header: 'tableCommon.sl_no',
      accessor: 'slno',
    },
    {
      Header: 'tableCommon.seriel_number',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'tableCommon.activityType',
      accessor: 'master_activity_name',
    },
    {
      Header: 'tableCommon.lot_code',
      accessor: 'lot_code',
    },
    {
      Header: 'tableCommon.status',    
      accessor: 'plate_status',
    },
]

interface TableColumn {
    Header: string;
    accessor: string;
  }
