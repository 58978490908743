import React, { useEffect, useState } from "react";
import moment from "moment";
import CustomTable from "../../../../widgets/table/index";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { columns } from "./partials/columns";
import { scheduleService } from "../../../../service/scheduleService";
import Pagination from "../../../../widgets/pagination";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../../widgets/alertModal";
import CustomToast from "../../../../widgets/toaster";
import { userService } from "../../../../service/userService";
import { useTranslation } from "react-i18next";
import { gradeTypes } from "../../../../service/gradeType";
import Select from "react-select";
import { helperFunctions as _h } from "../../../../utils/helper";

const ScheduleFrequency = () => {
  const { t } = useTranslation();
  const defaultFormData = {
    id: "",
    activity_type_id: "",
    op: "",
    area_grade_id: "",
    location_category_id: "",
    frequency: "",
    frequency_period: "",
    day: [],
    week: [],
    month: [],
    reason: "",
    status: "",
    created_at: "",
    updated_at: "",
    created_by: "",
    updated_by: "",
    activity_type_name: "",
    area_grade_name: "",
    location_category_name: "",
  };
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);

  // table pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [keyWord, setkeyWord] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddConfirmationAlert, setShowAddConfirmationAlert] =
    useState(false);
  const [statusChangeAlert, setStatusChangeAlert] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(defaultFormData);

  //toaster section
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState<number>(0);

  //handle error section
  interface Errors {
    activity_type_id?: string;
    area_grade_id?: string;
    op?: string;
    location_category_id?: string;
    frequency?: string;
    frequency_period?: string;
    day?: string;
    week?: string;
    month?: string;
    reason?: string;
  }
  const [masterFormData, setMasterFormData] = useState(defaultFormData);
  const [modalName, setModalName] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);
  const [categoryListData, setCategoryListData] = useState([]);
  const [grade, setGrade] = useState<Array<any>>([]);
  const [activityType, setActivityType] = useState<Array<any>>([]);
  const [isCopy, setIsCopy] = useState(false);
  const frequencyList = ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"];
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ].map((val) => {
    return {
      label: `${val}`,
      value: `${val}`,
    };
  });
  const weekList = ["1", "2", "3", "4", "5"].map((val) => {
    return {
      label: `Week-${val}`,
      value: `${val}`,
    };
  });
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((val) => {
    return {
      label: `${val}`,
      value: `${val}`,
    };
  });

  //handle user details for submit handling
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");
    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    getAreaCategoryList();
    listGrade();
    getActivityTypeList();
    getFrequencyList();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //handle onchange input fields
  const handleFieldOnChange = (e: any) => {
    const { name, value } = e.target;
    console.log("handleFieldOnChange=>{ name, value }", { name, value });
    setMasterFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log("handleFieldOnChange=>masterFormData", masterFormData);
  };

  //clear modal on close
  const handleFormClose = () => {
    setShowAddModal(false);
    setErrors({});
    setMasterFormData(defaultFormData);
  };

  //handle area validation starts here
  const handleFormValidation = () => {
    const errors: Errors = {};
    if (!masterFormData.activity_type_id) {
      errors.activity_type_id = "Please select an activity type";
    }
    if (!masterFormData.area_grade_id) {
      errors.area_grade_id = "Please select Grade Type";
    }
    if (!masterFormData.location_category_id) {
      errors.location_category_id = "Please select Area Category";
    }
    if (!masterFormData.op) {
      errors.op = "Please Select whether operational or non-operational";
    }
    if (!masterFormData.frequency) {
      errors.frequency = "Please Select frequency";
    }
    if (!masterFormData.frequency_period) {
      errors.frequency_period = "Please Select frequency period";
    }
    if (
      ["Weekly", "Monthly", "Yearly"].includes(masterFormData?.frequency) &&
      !_h.arrayHasData(masterFormData?.day)
    ) {
      errors.day = "Please select atleast one day";
    }
    if (
      ["Monthly", "Yearly"].includes(masterFormData?.frequency) &&
      !_h.arrayHasData(masterFormData?.week)
    ) {
      errors.week = "Please select atleast one week";
    }
    if (
      ["Yearly"].includes(masterFormData?.frequency) &&
      !_h.arrayHasData(masterFormData?.month)
    ) {
      errors.month = "Please select atleast one month";
    }
    if (masterFormData.id && !isCopy && !masterFormData.reason) {
      errors.reason = "Please enter reason for edit";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddConfirmationAlert(true);
    setErrors({});
  };

  //handle add area submit api call
  const handleFormSubmit = async () => {
    const dataToSend = {
      id: isCopy ? "" : masterFormData.id,
      activity_type_id: masterFormData.activity_type_id,
      area_grade_id: masterFormData.area_grade_id,
      location_category_id: masterFormData.location_category_id,
      op: masterFormData.op,
      frequency: masterFormData.frequency,
      frequency_period: masterFormData.frequency_period,
      day: masterFormData.day,
      week: masterFormData.week,
      month: masterFormData.month,
      interface_name: "Schedule Frequency",
      reason: masterFormData.reason,
    };

    try {
      const response = await scheduleService.createScheduleFrequency(
        dataToSend
      );
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getFrequencyList();
        handleFormClose();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowAddConfirmationAlert(false);
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  //table filter section
  const handleFilter = (key: string, value: any) => {
    if (key === "search") {
      setkeyWord(value);
    }
    setUseEffectRequired(true);
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //open add area modal
  const handleAddClick = () => {
    setShowAddModal(true);
    setModalName("Create Frequency Schedule");
  };

  const handleStatusChange = (id: string, status: string) => {
    setSelectedId(id);
    if (status === "active") {
      setUpdatedStatus("inactive");
    } else {
      setUpdatedStatus("active");
    }
    setStatusChangeAlert(true);
  };

  //change area status api call
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await scheduleService.changeFrequencyScheduleStatus(
        selectedId,
        updatedStatus,
        "Schedule Frequency"
      );

      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setUseEffectRequired(true);
      setUpdatedStatus("");
      setSelectedId("");
      setStatusChangeAlert(false);
    } catch (error) {
      console.error(error);
    }
  };

  //filter selected id based on the row selected while editing
  const handleEditClick = (id: string) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setMasterFormData(row[0]);
      setShowAddModal(true);
      setModalName("Edit Frequency Schedule");
      setIsCopy(false);
    }
  };

  const handleCopyClick = (row: any) => {
    if (_h.objectHasValue(row)) {
      console.log("handleEditClick=>row", row);
      setMasterFormData(row);
      setShowAddModal(true);
      setModalName("Copy Frequency Schedule");
      setIsCopy(true);
    }
  };

  const handleViewClick = (id: string, viewValue: boolean) => {
    const row = tableData.filter((item) => item.slno === id);
    if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
      console.log("handleEditClick=>row[0]", row[0]);
      setSelectedRow(row[0]);
      setShowViewModal(true);
    }
  };

  // get table data
  const getFrequencyList = async () => {
    try {
      const response = await scheduleService.getFrequencyList(
        page,
        pageSize,
        sort,
        order,
        keyWord
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  activity_type_name: element.activity_type_name,
                  area_grade_name: element.area_grade_name,
                  location_category_name: element.location_category_name,
                  op: element.op,
                  frequency: element.frequency,
                  frequency_period: element.frequency_period,
                  status: element.status,
                  activity_type_id: element.activity_type_id,
                  area_grade_id: element.area_grade_id,
                  location_category_id: element.location_category_id,
                  day: element.day,
                  week: element.week,
                  month: element.month,
                  created_at: element?.created_at
                    ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                  updated_at: element?.updated_at
                    ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                        "DD-MM-YYYY - HH:mm:ss"
                      )
                    : "-",
                };
              }
            );
            setTableData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get grades
  const listGrade = async () => {
    try {
      const response = await gradeTypes.listGradeOptions();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages);
            // setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,
                  id: element.id,
                };
              }
            );
            setGrade(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // api call to get area category list
  const getAreaCategoryList = async () => {
    try {
      const response = await scheduleService.getAreaCategoryList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  categoryName: element.category_name,
                  status: element.status,
                };
              }
            );
            setCategoryListData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCategoryListData([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // get Activity Type List
  const getActivityTypeList = async () => {
    try {
      const response = await scheduleService.getActivityTypeList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setActivityType(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setActivityType([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handleAddDayOnChange
  const handleAddDayOnChange = (options: any) => {
    if (_h.arrayHasData(options)) {
      const values = options.map((option: any) => option.value);
      setMasterFormData((prevData) => ({
        ...prevData,
        day: values,
      }));
    }
  };

  //handleAddWeekOnChange
  const handleAddWeekOnChange = (options: any) => {
    if (_h.arrayHasData(options)) {
      const values = options.map((option: any) => option.value);
      setMasterFormData((prevData) => ({
        ...prevData,
        week: values,
      }));
    }
  };

  //handleAddWeekOnChange
  const handleAddMonthOnChange = (options: any) => {
    if (_h.arrayHasData(options)) {
      const values = options.map((option: any) => option.value);
      setMasterFormData((prevData) => ({
        ...prevData,
        month: values,
      }));
    }
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("scheduleFrequency.pageTitle")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="me-3" style={{ marginTop: "10px" }}>
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            tableHead={t("scheduleFrequency.tableHead")}
            data={tableData}
            columns={columns}
            // isEditable={permission?.includes("edit_area")}
            isEditable={permission?.includes("edit_frequency_schedule")}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={true}
            onViewClick={handleViewClick}
            isSeachable={true}
            addButton={permission?.includes("add_frequency_schedule")}
            addButtonText={t("scheduleFrequency.add")}
            onFilterClick={handleFilter}
            onSortClick={handleSort}
            onAddClick={handleAddClick}
            isToggleStatus={true}
            onStatusChange={handleStatusChange}
            isQrDownloadable={false}
            onEditClick={handleEditClick}
            showQrGenerateButton={false}
            actionButtons={[
              {
                thLabel: t("scheduleFrequency.tableColumns.copy"),
                buttonLabel: t("scheduleFrequency.tableColumns.copy"),
                handleAction: (row: any) => {
                  console.log("customRowButton=>handleAction", row);
                  handleCopyClick(row);
                },
              },
            ]}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>

      {/* Alert modal for Add/edit Schedule */}
      <AlertModal
        show={showAddConfirmationAlert}
        onHide={() => setShowAddConfirmationAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleFormSubmit}
      />

      {/* Alert modal for change Schedule status */}
      <AlertModal
        show={statusChangeAlert}
        onHide={() => setStatusChangeAlert(false)}
        title="Alert"
        message={`Change Frequency Schedule Status?`}
        onConfirm={handleStatusChangeSubmit}
      />

      {/*modal for Add/edit Schedule */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAddModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleFrequency.activityType")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="activity_type_id"
                value={masterFormData?.activity_type_id}
                name="activity_type_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {activityType.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.activity_type_id && (
                <p className="errorText">{errors.activity_type_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label htmlFor="">
                {t("scheduleFrequency.areaGrade")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="area_grade_id"
                value={masterFormData.area_grade_id}
                name="area_grade_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {grade.map((obj, index) => (
                  <option key={index} value={obj?.id}>
                    {obj?.grade}
                  </option>
                ))}
              </select>
              {errors.area_grade_id && (
                <p className="errorText">{errors.area_grade_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label htmlFor="">
                {t("scheduleFrequency.locationCategory")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="location_category_id"
                value={masterFormData.location_category_id}
                name="location_category_id"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {categoryListData.map((categoryList: any) => (
                  <option key={categoryList.id} value={categoryList.id}>
                    {categoryList.categoryName}
                  </option>
                ))}
              </select>
              {errors.location_category_id && (
                <p className="errorText">{errors.location_category_id}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleFrequency.opNonOp")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="op"
                value={masterFormData?.op}
                name="op"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                <option key={"operational"} value={"operational"}>
                  {"operational"}
                </option>
                {/* {["Weekly", "Monthly", "Yearly"].includes(
                  masterFormData?.frequency
                ) && (
                  <option key={"non-operational"} value={"non-operational"}>
                    {"non-operational"}
                  </option>
                )} */}
                <option key={"non-operational"} value={"non-operational"}>
                  {"non-operational"}
                </option>
              </select>
              {errors.op && <p className="errorText">{errors.op}</p>}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleFrequency.frequency")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="frequency"
                value={masterFormData?.frequency}
                name="frequency"
                onChange={handleFieldOnChange}
              >
                <option value="">select</option>
                {frequencyList.map((frequency: any) => (
                  <option key={frequency} value={frequency}>
                    {frequency}
                  </option>
                ))}
              </select>
              {errors.frequency && (
                <p className="errorText">{errors.frequency}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleFrequency.frequency_period")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="frequency_period"
                value={masterFormData.frequency_period}
                name="frequency_period"
                type="text"
                placeholder={t("scheduleFrequency.frequency_period")}
                className="placeholder-style"
                onChange={handleFieldOnChange}
              />
              {errors.frequency_period && (
                <p className="errorText">{errors.frequency_period}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            {["Weekly", "Monthly", "Yearly"].includes(
              masterFormData?.frequency
            ) && (
              <div className={`form-group my-1 mx-3 px-2`}>
                <label>
                  {t("scheduleFrequency.day")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Select
                  options={weekDays}
                  onChange={handleAddDayOnChange}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  placeholder={t("scheduleFrequency.selectDays")}
                  // className='multiSelectRole'
                  value={
                    _h.arrayHasData(masterFormData?.day)
                      ? masterFormData?.day.map((val) => {
                          return {
                            label: `${val}`,
                            value: `${val}`,
                          };
                        })
                      : []
                  }
                />
                {errors.day && <p className="errorText">{errors.day}</p>}
              </div>
            )}

            {["Monthly", "Yearly"].includes(masterFormData?.frequency) && (
              <div className={`form-group my-1 mx-3 px-2`}>
                <label>
                  {t("scheduleFrequency.week")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Select
                  options={weekList}
                  onChange={handleAddWeekOnChange}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  placeholder={t("scheduleFrequency.selectWeeks")}
                  // className='multiSelectRole'
                  // value={weekList.filter((val) =>
                  //   masterFormData.week.includes(String(val?.value))
                  // )}
                  value={
                    _h.arrayHasData(masterFormData?.week)
                      ? masterFormData?.week.map((val) => {
                          return {
                            label: `Week-${val}`,
                            value: `${val}`,
                          };
                        })
                      : []
                  }
                />
                {errors.week && <p className="errorText">{errors.week}</p>}
              </div>
            )}

            {["Yearly"].includes(masterFormData?.frequency) && (
              <div className={`form-group my-1 mx-3 px-2`}>
                <label>
                  {t("scheduleFrequency.month")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <Select
                  options={monthList}
                  onChange={handleAddMonthOnChange}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  placeholder={t("scheduleFrequency.selectMonths")}
                  // className='multiSelectRole'
                  // value={monthList.filter((val) =>
                  //   masterFormData.month.includes(String(val?.value))
                  // )}
                  value={
                    _h.arrayHasData(masterFormData?.month)
                      ? masterFormData?.month.map((val) => {
                          return {
                            label: `${val}`,
                            value: `${val}`,
                          };
                        })
                      : []
                  }
                />
                {errors.month && <p className="errorText">{errors.month}</p>}
              </div>
            )}
          </div>
          <div className="selectWrap-new">
            {masterFormData.id && !isCopy && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={masterFormData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleFieldOnChange}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
          </div>

          <div className="bottomArea">
            <button
              onClick={() => handleFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleFormValidation()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={showViewModal}
        className="modal-view-audit"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton onClick={() => setShowViewModal(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("scheduleFrequency.view")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ textAlign: "left" }}>
            {t("scheduleFrequency.scheduleFrequencyDetails")}
          </h4>
          {_h.objectHasValue(selectedRow) && (
            <>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.activity_type_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.activity_type_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.area_grade_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.area_grade_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.location_category_name")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.location_category_name}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.op")}
                  </div>
                  <div className="audit-value">{selectedRow?.op}</div>
                </div>
              </div>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.frequency")}
                  </div>
                  <div className="audit-value">{selectedRow?.frequency}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.frequency_period")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.frequency_period}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.created_at")}
                  </div>
                  <div className="audit-value">{selectedRow?.created_at}</div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.tableColumns.updated_at")}
                  </div>
                  <div className="audit-value">{selectedRow?.updated_at}</div>
                </div>
              </div>
              <div
                className="key-value-pairs"
                style={{
                  display: "flex",
                  padding: "10px",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">{t("scheduleFrequency.day")}</div>
                  <div className="audit-value">
                    {selectedRow?.day.join(", ")}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">{t("scheduleFrequency.week")}</div>
                  <div className="audit-value">
                    {`week-${selectedRow?.week.join(", week-")}`}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.month")}
                  </div>
                  <div className="audit-value">
                    {selectedRow?.month.join(", ")}
                  </div>
                </div>
                <div className="column" style={{ flex: "1" }}>
                  <div className="audit-key">
                    {t("scheduleFrequency.status")}
                  </div>
                  <div className="audit-value">{selectedRow?.status}</div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <button
            onClick={() => setShowViewModal(false)}
            className="custom-close-button"
          >
            {t("buttons.close")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScheduleFrequency;
