export const columns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_number",
    },
    {
        Header: "Batch GRN",
        accessor: "batch_grn",
    },
     {
        Header: "plateInventory.tableColumns.manufacture_details",
        accessor: "manufacturer_details",
    },
    {
        Header: "Quantity",
        accessor: "quantity",
    },
    {
        Header: "plateInventory.tableColumns.plate_type",
        accessor: "plate_type",
    },
    {
        Header: "plateInventory.tableColumns.status",
        accessor: "status",
    },
  ];


export const columnsByBatch: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "Batch Code",
        accessor: "batch_no",
    },
    {
        Header: "Lot Code",
        accessor: "lot_code",
    },
    {
        Header: "plateInventory.tableColumns.quantity",
        accessor: "plate_count",
    },
    {
        Header: "Inspection Status",
        accessor: "inspection_status",
    },
  ];


export const columnsByLotBatch: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "Batch Code",
        accessor: "batch_no",
    },
    {
        Header: "Lot Code",
        accessor: "lot_code",
    },
    {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
    },
    {
        Header: "Manufacturing Date",
        accessor: "manufacturing_date",
    },
    {
        Header: "Expiry Date",
        accessor: "expiry_date",
    }, 
    {
        Header: "Status",
        accessor: "status",
    },
  ];


export const discardColumns: TableColumn[] = [
    {
        Header: "tableCommon.sl_no",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
    },
    {
        Header: "plateInventory.tableColumns.manufacture_date",
        accessor: "manufacturingDate",
    },
    // {
    //     Header: "plateInventory.tableColumns.expiry_date",
    //     accessor: "expiry_date",
    // },
    {
        Header: "plateInventory.tableColumns.quantity",
        accessor: "quantity",
    },
    // {
    //     Header: "plateInventory.tableColumns.status",
    //     accessor: "status",
    // },
]

  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
