import React, { useEffect, useState } from "react";
import { rolesAndPermission } from "../../../../../service/rolesAndPermission";
import AlertModal from "../../../../../widgets/alertModal";
import { Link} from "react-router-dom";


interface InnerPageRolesProps {
  setTab: (type: string) => void;
  setRole: (type: string) => void;
  setReason: (type: string) => void;
  setSelectedCodes: (type: any) => void;
  role: string;
  reason: string;
  selectedCodes: any;
  id: any;
  setUseEffect: (type: boolean) => void;
  setShowToast: (type: any) => void;
  setToastMessage: (type: any) => void;
  setToastType:(type: any) => void;
  setId:(type: any) => void;
  setModalName : (type: any) => void;
  modalName : string;
}

const InnerPageRoles: React.FC<InnerPageRolesProps> = ({ setTab , setRole, setSelectedCodes , role, setReason, reason , selectedCodes, id, setUseEffect, setShowToast , setId,setToastMessage, setToastType , setModalName , modalName}) => {
  
  
  const [moduleNames, setModuleNames] = useState<Array<any>>([]);
  const [permissionTablelist, setPermissionTable] = useState<Array<any>>([]);
  const [selExposureAreaId, setSelExposureAreaId] = useState(1);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [selectAllCodes, setSelectAllCodes] = useState<Array<any>>([]);

  interface Errors {
    reason?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState<number>(0);
  const [roleAlert, setRoleAlert] = useState(false);

  useEffect(() => {
    var storedData = localStorage.getItem('userDetails');

    if(storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id)
    }
    getModuleName();
    permissionTable(undefined);
  }, []);

  const handleRoleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    const regex = /^[a-zA-Z ]*$/;
    if (name === "reason") {
      setReason(value);
    } else if (regex.test(value) && value.length <= 150) {
      setRole(value);
    }
  };

  const getModuleName = async () => {
    try {
      const response = await rolesAndPermission.getModuleName();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setPermissionTable([])
            const firstId = rowData.length > 0 ? rowData[0]?.id : null;
            permissionTable(firstId)
            setSelExposureAreaId(firstId)
            setModuleNames(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("exposure response", error);
    }
  };

  const permissionTable = async (id: number | undefined) => {
    try {
      const response = await rolesAndPermission.permissionTable(id);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            if (!id) {
              let codes: string[] = response.data.map(
                (element: any) => element.code
              );
              setSelectAllCodes(codes);
            } else {
              let rowData: any = response.data.map(
                (element: any, index: number) => {
                  return {
                    slno: index + 1,
                    id: element.id,
                    title: element.title,
                    code: element.code,
                    status: element.status,
                    module_id: element.module_id,
                  };
                }
              );

              setPermissionTable(rowData);
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPermissionTable([]);
      }
    } catch (error) {
      console.error("exposure response", error);
    }
  };

  const onExpAreaNameClick = (id: number, name: string) => {
    setSelExposureAreaId(id);
    permissionTable(id);
  };

  useEffect(() => {
    permissionTable(selExposureAreaId)
    setUseEffectRequired(false)
  }, [useEffectRequired, selExposureAreaId])


  const handleCheckboxChange = (code:any) => {
    setSelectedCodes((prevSelectedCodes: any) => {
      if (prevSelectedCodes.includes(code)) {
        return prevSelectedCodes.filter((c:any) => c !== code);
      } else {
        return [...prevSelectedCodes, code];
      }
    });
  };

 const handleRoleSubmit = async () => {
  const permissionsString = selectedCodes.join(',');
  const dataToSend = {
    "user_id" : userId,
    "name" : role,
    "permissions" : permissionsString,
    "id" : id,
    "interface_name" : "Roles and Permission" ,
    "reason" : reason
  }
  try {
    const response = await rolesAndPermission.addRole(dataToSend);
    if(response.status && response.statusCode === 200){
      setToastType("success")
      setToastMessage(response.message)
      setShowToast(true)
      setRoleAlert(false)
      handleCancel();
      setUseEffect(true)
      setUseEffectRequired(true)
      setTab("1")
    } else {
      setToastType("error")
      setToastMessage(response.message)
      setShowToast(true)
      setRoleAlert(false)

    }
   
  } catch (error) {
    console.error('Plate response', error);
  }
}
 
 const handleCancel = () => {
  setSelectedCodes([]);
  setRole("");
  setReason("");
  setId("");
  setTab("1")
  setId("")
  setErrors({})
 }

 const handleSubmit = () => {
  const errors: Errors = {};
 
  if (id && !reason) {
    errors.reason = "Please enter reason";
  }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setRoleAlert(true);
    setErrors({});
  };

  const handleSelectAllClick = () => {
    if (selectAllCodes?.length===selectedCodes?.length) {
      setSelectedCodes([]);
    } else {
      setSelectedCodes(selectAllCodes);
    }
  };

  return (
    <div>   
     <nav aria-label="breadcrumb" className="breadcrumb-container row">
      <div className='col-10 d-flex'>
        <ol className="breadcrumb">
          <li className="breadcrumb-item dashboard-hover">
          <Link to="/rolesAndPermission" onClick={(e) => handleCancel()}>
             Roles and Permission
          </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">{modalName} Role</li>
        </ol>
      </div>
    </nav>

      <div className="form-group my-3 px-2 ">
        <label htmlFor="">
        {`${modalName} Role`}<span style={{ color: "red" }}>*</span>
        </label>
        <br />
        <input
          id="userId"
          value={role}
          name="userId"
          type="text"
          placeholder="Create Role"
          className="create-role"
          onChange={handleRoleChange}
          maxLength={150}
        />
      </div>

      {id && (
      <div className="form-group my-3 px-2 ">
        <label htmlFor="" className='ReasonLabel'>Reason<span style={{ color: "red" }}>*</span></label>
        <br />
        <input 
          id="reason"
          value={reason}
          name="reason"
          type='text'
          placeholder='Reason'
          className="create-reason"
          onChange={handleRoleChange} 
          />
          {errors.reason && <p className="errorText">{errors.reason}</p>}
      </div>
      )}

      <div className="mt-5 row">
        <div className="col-md-2 area-names ml-5 ">
          <h3 className="filter-head mb-3" style={{ textAlign: "center" }}>
            Module
          </h3>
          <div
            className="outer-area"
            style={{ overflowY: "scroll", height: "60vh" }}
          >
            {moduleNames &&
              moduleNames.map((exp_names) => (
                <div
                  className={
                    permissionTablelist[0]?.module_id === exp_names?.id
                      ? "selAreaDetails"
                      : "area-detail"
                  }
                  key={exp_names.id}
                  onClick={() =>
                    onExpAreaNameClick(exp_names.id, exp_names.name)
                  }
                >
                  {exp_names.name}
                </div>
              ))}
          </div>
        </div>

        <div className="col-md-9 table-footer">
          <div className="form-group" style={{marginTop: "15px" , marginBottom: "10px"}}>
            <button className="squareOrangeBtn3" onClick={handleSelectAllClick}>
              {selectAllCodes?.length === selectedCodes?.length ? "Unselect All" : "Select All"}
            </button>
          </div>
          <table className="checkbox-table ">
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Permission</th>    
                    <th>Select</th>
                </tr>
            </thead>
            <tbody>
                {permissionTablelist.map((permission, index) => (
                    <tr key={permission.id}>
                        <td>{index + 1}</td>
                        <td>{permission.title}</td>
                        <td>
                            <input
                                type="checkbox"
                                checked={selectedCodes.includes(permission.code)}
                                onChange={() => handleCheckboxChange(permission.code)}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
        <div className="col-md-11 colonyCountBottomSec mx-5 py-1 mb-5">
          <button className="modalCancelBtn" onClick={() => handleCancel()}>
            Cancel
          </button>
          <button className="squareOrangeBtn3" onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>

      <AlertModal 
          show={roleAlert}
          onHide={() => setRoleAlert(false)}
          title='Alert'
          message={`${modalName} New Role`}
          onConfirm={handleRoleSubmit}
        />
    </div>
  );
};

export default InnerPageRoles;
