import HeaderSection from "../../../../common/headerSection";
import PageHeader from "../../../../common/pageHeader";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ParetoChart from "../../partials/paretoChart";
import { useEffect, useState } from "react";
import Chart from "../../partials/chart";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  ScheduleSummary,
  DiscrepancySummary,
  ExceptionSummary,
} from "../Violation/columns";
import DownloadPdf from "../../../../../widgets/downloadPdf";
import { useTranslation } from "react-i18next";
import { trendAnalytics } from "../../../../../service/trendAnalytics";
import { userService } from "../../../../../service/userService";
import { FiFilter } from "react-icons/fi";

const LandingComponent = () => {
  const navigate = useNavigate();
  const { bulletId } = useParams<{ bulletId: string }>();
  const { t } = useTranslation();
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [filterData, setFilterData] = useState({
    area: "",
    location: "",
    activityType: "",
    operatorType: "",
    organismType: "",
    organismGenus: "",
    organismSpecies: "",
    organismStrain: "",
    fromDate: "",
    toDate: "",
    graphTypeSelect: "",
  });
  const [areaDropDown, setareaDropDown] = useState<Array<any>>([]);
  const [locationDropDown, setlocationDropDown] = useState<Array<any>>([]);
  const [path, setPath] = useState<string>("");
  const [violationType, setViolationType] = useState<string>("");
  const [activityTypes, setActivityTypes] = useState<Array<any>>([]);
  const [userData , setUserData] = useState<Array<any>>([]);
  const [organismType , setOrganismType] = useState<Array<any>>([]);  
  const [genus , setGenus] = useState<Array<any>>([]); 
  const [species , setSpecies] = useState<Array<any>>([]);  
  const [strain , setStrain] = useState<Array<any>>([]); 
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  type LabelType = string[];
  const [barLabels, setbarLabels] = useState<LabelType>([]);
  const [pieChartData, setPieChartData] = useState<Array<any>>([])

  interface ChartType {
    label: string;
    component: any;
    backgroundColors: string[];
    borderColors: string[];
    stack?: boolean;
    fill?: boolean;
    selectedOption?: string;
    selectedGraph?: string;
    graphNameType?: string[];
    selectedGraphName?: string;
    xAxisSelector?: string[];
  }

  interface ChartTypes {
    [key: string]: ChartType;
  }
  const chartTypes: ChartTypes = {
    bar: {
      label: "Bar Chart",
      component: Bar,
      backgroundColors: ["rgba(53, 162, 235, 0.5)", "rgba(23, 152, 225, 0.5)"],
      borderColors: ["rgb(53, 162, 235)", "rgb(23, 152, 225)"],
    },
    line: {
      label: "Line Chart",
      component: Line,
      backgroundColors: ["rgba(53, 162, 235, 0)", "rgba(23, 152, 225, 0)"],
      borderColors: ["rgb(53, 162, 235)", "rgb(23, 152, 225)"],
    },
    area: {
      label: "Area Chart",
      component: Line,
      backgroundColors: ["rgba(75, 192, 192, 0.5)", "rgba(54, 162, 235, 0.5)"],
      borderColors: ["rgb(75, 192, 192)", "rgb(54, 162, 235)"],
    },
    stackedBar: {
      label: "Stacked Bar Chart",
      component: Bar,
      backgroundColors: ["rgba(53, 162, 235, 0.5)", "rgba(23, 152, 225, 0.5)"],
      borderColors: ["rgb(53, 162, 235)", "rgb(23, 152, 225)"],
      stack: true,
    },
    pie: {
      label: "Pie Chart",
      component: Pie,
      backgroundColors: [
        "rgba(53, 162, 235, 0.5)",
        "rgba(23, 152, 225, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(255, 205, 86, 0.5)",
        "rgba(255, 99, 132, 0.5)",
      ],
      borderColors: [
        "rgb(53, 162, 235)",
        "rgb(23, 152, 225)",
        "rgb(75, 192, 192)",
        "rgb(255, 205, 86)",
        "rgb(255, 99, 132)",
      ],
    },
  };



  const graphColors = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];



  interface ChartData {
    labels: string[];
    datasets: {
      label?: string;
      data: number[];
      backgroundColor: any;
      borderColor?: string;
      borderWidth?: number;
      fill?: boolean; 
    }[];
  }


let data: ChartData 

if (filterData.graphTypeSelect === "pie") {
  data = {
    labels: barLabels || [], // Default to an empty array if barLabels is undefined
    datasets: [{
      data: pieChartData || [], 
      backgroundColor: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      borderWidth: 0,
      borderColor: "none"
    }]
  };
} else {
  data = {
    labels: barLabels || [],
    datasets: barLabels.map((label, index) => ({
      label: label,
      data: barLabels.map((_, i) => i === index ? pieChartData[index] : 0),
      backgroundColor: graphColors[index] || '#000000',
      borderColor: graphColors[index] || '#000000',
      borderWidth: 1,
      fill: filterData.graphTypeSelect === "area"
    }))
    
  };
}

  useEffect(() => {
    const areaMapping: { [key: string]: string } = {
      area: "Area",
      location: "Location",
      operator: "Operator",
      microbiologist: "Microbiologist",
      day: "Day",
      week: "Week",
      month: "Month",
      year: "Year",
      quarter: "Quarter",
    };
  
    // Extract the area and cleanedBulletId from bulletId
    let area = '';
    let cleanedBulletId = '';
  
    if (bulletId) {
      const [baseBulletId, areaWord] = bulletId.split('_');
      area = areaWord || ''; 
      cleanedBulletId = baseBulletId;
    }
  
    // Set selected area based on the cleanedBulletId
    setViolationType(area)
    setPath(cleanedBulletId)
    setSelectedArea(areaMapping[cleanedBulletId] || "");
    getViolationsSummary(cleanedBulletId, area);
    listAreaType();
    listActivityTypes();
    getOperatorList();
    listTypeOptions();
  }, [bulletId]);
  

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "area") {
      listLocationType(value);
    }
    if(name === "organismType"){
      handleOrganismTypeChange(value); 
    }
    if(name === "organismGenus"){
      handleGenusTypeChange(value); 
    }
    if(name === "organismSpecies"){
      handleSpeciesTypeChange(value); 
    }
  };

  //list operator dropdown
  const getOperatorList = async () => {
    try {
      // const userRole = parseInt(addFilterData.role, 10);  
      const response = await userService.getUserNameList(1);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              // setTotalPages(response?.totalPages)
              // setTotalElements(response?.totalElements)
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    slno: index + 1, 
                    id: element.id,
                    name: `${element.first_name} ${element.last_name}`,
                };
              });
              setUserData(rowData);
            }
          } catch (error) {
            console.log(error);
          }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //list area types dropdown
  const listAreaType = async () => {
    try {
      const response = await trendAnalytics.listAreaType();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.id,
                  label: element.name,
                  type: element.type,
                };
              }
            );
            setareaDropDown(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //list location types dropdown
  const listLocationType = async (parent_area: string) => {
    try {
      const response = await trendAnalytics.listLocationType(parent_area);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  value: element.id,
                  label: element.name,
                  type: element.type,
                };
              }
            );
            setlocationDropDown(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //list shift dropdown
  // const listShiftOptions = async () => {
  //   try {
  //     const response = await trendAnalytics.listShiftOptions();
  //     if (response.status && response.statusCode === 200) {
  //       try {
  //         if (Array.isArray(response.data) && response.data?.length > 0) {
  //           let rowData: any = response.data.map(
  //             (element: any, index: number) => {
  //               return {
  //                 value: element.id,
  //                 label: element.name,
  //               };
  //             }
  //           );
  //           setShift(rowData);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Plate response", error);
  //   }
  // };

  //list activity types
  const listActivityTypes = async () => {
    try {
      const response = await trendAnalytics.listActivityTypes();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  label: element.name,
                  value: element.id,
                };
              }
            );
            setActivityTypes(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("Plate response", error);
    }
  };

  //api call to organism type
  const listTypeOptions = async () => {
  try {
    const response = await trendAnalytics.listTypeOptions();  
    if (response.status && response.statusCode === 200) {
      const rowData = response.data.map((element:any, index:any) => ({
        sl_no: index + 1,
        id: element.id,
        organism_type: element.organism_type
      }));
      setOrganismType(rowData);
    } else {
      setOrganismType([]);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  };

  //api call to get genus
  const handleOrganismTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listGenusOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          genus_name: element.genus_name
        }));
        setGenus(rowData);
      } else {
        setGenus([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  //api call to get species
  const handleGenusTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listSpeciesOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name
        }));
        setSpecies(rowData);
      } else {
        setSpecies([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  //api call to get strain
  const handleSpeciesTypeChange = async (selectedTypeId: string) => {
    try {
      const response = await trendAnalytics.listStrainOptions(selectedTypeId);
      if (response.status && response.statusCode === 200) {
        const rowData = response.data.map((element:any, index:any) => ({
          sl_no: index + 1,
          id: element.id,
          name: element.name
        }));
        setStrain(rowData);
      } else {
        setStrain([]);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate =
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
        `${date.getDate().toString().padStart(2, '0')} ` +
        `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    return formattedDate;
};

  const handleCancel = () => {
    const initialFormData = {
      area: "",
      location: "",
      activityType: "",
      operatorType: "",
      organismType: "",
      organismGenus: "",
      organismSpecies: "",
      organismStrain: "",
      fromDate: "",
      toDate: "",
      graphTypeSelect: ""
    };
    setFilterData(initialFormData);
    setlocationDropDown([]);
  };

  const handleApplyClick = () => {
    getViolationsSummary(path, violationType);
  };

  const getViolationsSummary = async (path: string, type: string) => {
    try {
      let response;
      if (type === "exception") { 
        response = await trendAnalytics.getExceptionViolationsSummary(filterData, path);
      } else {
        response = await trendAnalytics.getViolationsSummary(filterData, path);
      }
      if (response.status && response.statusCode === 200) {
        const plateDetails = response.data;
        if (Array.isArray(plateDetails) && plateDetails.length > 0) {
          const graphLabels = plateDetails.map((detail: any) => detail.graph_label || '');
          const violationCount = plateDetails.map((detail: any) => detail.violation_count || 0);
          
          setbarLabels(graphLabels);
          setPieChartData(violationCount);

        } else {
          console.log("No plate details found.");
        }
      } else {
        console.log(`Unexpected response: ${response.statusCode}`);
      }
    } catch (error) {
      console.error("Failed to fetch excursion analytics data:", error);
    }
  };


  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="Violations Report" />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">
                {t("dashboard.analyticalDashboard")}
              </Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Violations">
                {t("dashboard.violationsReport")}
              </Link>
            </li>
            <li
              className="breadcrumb-item active selectedArea"
              aria-current="page"
            >
              {violationType.charAt(0).toUpperCase() + violationType.slice(1)} {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <div>
              <button onClick={() => setShowAdvanceFilter(!showAdvanceFilter)} className="filterBtn">{t('buttons.filter')} <FiFilter /></button>
          </div>
          <DownloadPdf
            divId="dashWrap"
            reportHead={t("dashboard.violationsReport")}
          />
        </div>
      </nav>

      <div>
        <div className="d-flex justify-content-around mt-3">
          {/* Area filter */}
          <div
            className="select-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.select_area")}:
            </label>
            <select
              className="areaTypeSelect"
              name="area"
              value={filterData.area}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select</option>
              {areaDropDown?.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>

          {/* location filter */}
          <div
            className="select-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.select_location")}:
            </label>
            <select
              className="areaTypeSelect"
              name="location"
              value={filterData.location}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select</option>
              {locationDropDown?.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>

          {/* Graph Type filter */}
          <div
            className="select-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.select_graph_type")}{" "}
              <span style={{ color: "red" }}>*</span>:
            </label>
            <select
              className="areaTypeSelect"
              name="graphTypeSelect"
              value={filterData.graphTypeSelect}
              onChange={(e) => {
                handleInputChange(e);
              }}
            >
              {Object.keys(chartTypes).map((type) => (
                <option key={type} value={type}>
                  {chartTypes[type].label}
                </option>
              ))}
            </select>
          </div>

          {/* Operator filter */}
          <div
            className="select-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              Select Operator:
            </label>
            <select
              className="areaTypeSelect"
              name="operatorType"
              value={filterData.operatorType}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select</option> 
              {userData?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

           {/* From Date */}
           <div
              className="select-container analyticFilterDate"
              style={{ display: "flex", flexDirection: "column"}}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.from_date")}:
              </label>
              <input 
                type="datetime-local" 
                value={formatDateTime(filterData.fromDate)}
                name="fromDate"
                onChange={(e) => handleInputChange(e)} 
              />
            </div>

          {/* To Date */}
          <div
            className="select-container analyticFilterDate"
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.to_date")}:
            </label>
            <input 
              type="datetime-local" 
              value={formatDateTime(filterData.toDate)}
              name="toDate"
              onChange={(e) => handleInputChange(e)} 
              />
          </div>
        </div>

        <div hidden={!showAdvanceFilter}>
        <div className="d-flex justify-content-around mt-3">
          {/* Activity filter */}
          <div
            className="select-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
              {t("common.select_activity")}{" "}
              <span style={{ color: "red" }}>*</span>:
            </label>
            <select
              className="areaTypeSelect"
              name="activityType"
              value={filterData.activityType}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select</option>
              {activityTypes?.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>

             {/* Organism Type filter */}
             <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_type")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismType"
                value={filterData.organismType}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {organismType?.map((type : any) => (
                  <option key={type.id} value={type.id}>
                    {type.organism_type}
                  </option>
                ))} 
              </select>
            </div>

            {/* Organism Genus filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_genus")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismGenus"
                value={filterData.organismGenus}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {genus?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.genus_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Organism Species filter */}
            <div
              className="select-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_species")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismSpecies"
                value={filterData.organismSpecies}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {species?.map((type: any) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Organism Strain filter */}
            <div
              className="select-container ms-2 ps-1"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_strain")}:
              </label>
              <select
                className="areaTypeSelect"
                name="organismStrain"
                value={filterData.organismStrain}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {strain?.map((type: any) => (
                  <option key={type.id} value={type.id}>
                  {type.name}
                </option>
                ))}
              </select>
            </div>

            <div
              className="select-container ms-2 ps-1 mx-5"
              style={{ display: "flex", flexDirection: "column", visibility:"hidden" }}
            >
              <label htmlFor="" style={{ color: "black", fontSize: "12px" }}>
                {t("common.select_organism_strain")}:
              </label>
              <select
                className="areaTypeSelect"
                // name="organismStrain"
                // value={filterData.organismStrain}
                // onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select</option>
                {/* {strain?.map((type: any) => (
                  <option key={type.id} value={type.id}>
                  {type.name}
                </option>
                ))} */}
              </select>
            </div>


        </div>   
        </div>
        <div className="mt-3 mx-2 d-flex justify-content-end">
            <button
              className="analyticalFilterClose"
              title="Click here to Reset Filter"
              onClick={handleCancel}
            >
              {t("buttons.cancel")}
            </button>
            <button
              className="analyticalFilterApply"
              title="Click here to Apply Filter"
              onClick={handleApplyClick}
            >
              {t("buttons.apply")}
            </button>
          </div>

        <div
          className="row mx-2 mt-5"
          id="dashWrap"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-9">
            <div className="">
              <Chart
              chartType={filterData.graphTypeSelect}
              data={data}
              selectedOption={selectedArea}
              selectedGraph={"Violation"}
              selectedGraphName={violationType}
              activitySelect= {filterData.activityType}
              xaxisRange={selectedArea}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingComponent;
