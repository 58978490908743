import axios from "axios";
import baseUrl from "../index";
import { handleLogOut } from "../../utils/oathUtils";
let authToken = ""
let authUserID = ""

const storedData = localStorage.getItem('userDetails');

if (storedData) {
  try {
    const storedObject = JSON.parse(storedData);
    if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
      authToken = storedObject[0].x_auth_token
      authUserID = storedObject[0].x_userid
    }
  } catch (error) {
    console.error('Failed to parse user details:', error);
  }
}

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );



  const getBatchList =  async (page :number, size :number, batch_no:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/batches/list?page=${page}&size=${size}&sort=id&order=asc&plate_status=&batch_number=${batch_no}&keyword=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  const listPlateBatch = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_batch`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  // const ListLots = async (page :number, size :number, lot_code : any) => {
  //   try {
  //     const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?page=${page}&size=${size}&sort=id&order=asc&status=active&keyword=&inspection_status=pending&batch_no&lot_code=${lot_code}&batch_id=`,
  //     {
  //       headers: {
  //         'x-auth-token': authToken,
  //         'x-userid': authUserID
  //       }
  //     });
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       return error.response?.data
  //     }
  //   }
  // }

   const ListLots = async (page: number, pageSize: number, batch_id: any, lot_id: any) => {
      try {
      const response = await axiosInstance.get(`${baseUrl}/lots/list_lots?page=${page}&size=${pageSize}&sort=id&order=desc&inspection_status=failed,passed,pending&batch_no=&batch_id=${batch_id}&lot_id=${lot_id}`,
      {
          headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
          }
      });
      return response.data;
      } catch (error) {
      if (axios.isAxiosError(error)) {
          return error.response?.data
      }
      }
    };

  const getPlateList = async (page :number, size :number, id:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?sort=id&order=asc&status=active&plate_status=&keyword=&batch_number=&serial_number=&current_location_id=&location=&incubator_id&lot_id=${id}&lot_code`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getPlateListByBatchId = async (page :number, size :number, id:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?page=${page}&size=${size}&sort=id&order=asc&status=active&plate_status=&keyword=&batch_number=&serial_number=&current_location_id=&location=&incubator_id&lot_id=&lot_code=&batch_id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getActivityConcilation = async (page :number, size :number, id:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/reconciliation/activity_wise_reconciliation_by_batch?page=${page}&size=${size}&batch_id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getActivityConcilationByLotId = async (page :number, size :number, id:any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/reconciliation/activity_wise_reconciliation_by_lot?page=${page}&size=${size}&lot_id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const getPlateListApi = async (id:any , serial_number: any) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/plates/list_plate_details?sort=id&order=asc&status=active&plate_status=&keyword=&batch_number=&serial_number=${serial_number}&current_location_id=&location=&incubator_id&lot_id=${id}&lot_code`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const PlateReconcilationApi = {
    getBatchList,
    listPlateBatch,
    ListLots,
    getPlateList,
    getPlateListByBatchId,
    getActivityConcilation,
    getActivityConcilationByLotId,
    getPlateListApi
  };

  export { PlateReconcilationApi };