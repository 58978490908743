import React, { useEffect, useState } from "react";
import CustomTable from "../../../widgets/table/index";
import HeaderSection from "../../common/headerSection";
import PageHeader from "../../common/pageHeader";
import { columns } from "./partials/columns";
import { areaService } from "../../../service/areaServices";
import Pagination from "../../../widgets/pagination";
import { Button, Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import AlertModal from "../../../widgets/alertModal";
import CustomToast from "../../../widgets/toaster";
import { userService } from "../../../service/userService";
import { useTranslation } from "react-i18next";
import LocationManagement from "./partials/locationManagement";
import baseUrl from "../../../service";
import { gradeTypes } from "../../../service/gradeType";

const AreaManagement = () => {
  const { t } = useTranslation();
  const [areaData, setAreaData] = useState<Array<any>>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  // table pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [areaType, setAreaType] = useState("8,12,9,6");
  const [keyWord, setkeyWord] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddAreaAlert, setShowAddAreaAlert] = useState(false);
  const [areaAlert, setAreaAlert] = useState(false);

  //toaster section
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  //handle error section
  interface Errors {
    locationName?: string;
    locationType?: string;
    parentArea?: string;
    policyName?: string;
    duration?: string;
    gradeType?: string;
    capacity?: string;
    areaCode?: string;
    plant?: string;
    unit?: string;
    line?: string;
    building?: string;
    areaCategory?: string;
    reason?: string;
  }
  const [errors, setErrors] = useState<Errors>({});
  const [userId, setUserId] = useState<number>(0);
  const [addAreaData, setAddAreaData] = useState({
    plant: "",
    unit: "",
    building: "",
    locationName: "",
    discription: "",
    locationType: "",
    parentArea: "0",
    policyName: "",
    duration: "0",
    gradeType: "",
    capacity: "0",
    areaCode: "",
    id: "",
    areaCategory: "",
    reason: "",
  });
  const [policyName, setPolicyName] = useState<Array<any>>([]);
  const [selectedPolicyDuration, setSelectedPolicyDuration] = useState("");
  const [modalName, setModalName] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [areaId, setAreaId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showGenerateQrAlert, setShowGenerateQrAlert] = useState(false);
  const [selectedSlnoLen, setSelectedSlnoLen] = useState(0);
  const [selectedSerielNumbers, setSelectedSerielNumbers] = useState<string[]>(
    []
  );
  const [imageId, setImageId] = useState("");
  const [cellWidth, setCellWidth] = useState(1);
  const [areas, setAreas] = useState<Array<any>>([]);
  const [tab, setTab] = useState("1");
  const [plantArea, setPlantArea] = useState<Array<any>>([]);
  const [unitArea, setUnitArea] = useState<Array<any>>([]);
  const [BuildingArea, setBuildingArea] = useState<Array<any>>([]);
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("desc");
  const [permission, setPermission] = useState<any>([]);
  const [categoryListData, setCategoryListData] = useState([]);
  const [grade, setGrade] = useState<Array<any>>([]);

  //handle user details for submit handling
  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");

    if (storedData) {
      var storedObject = JSON.parse(storedData);
      setUserId(storedObject[0].id);
      getPermissionByRole(storedObject[0].role_master_id);
    }
    geAreaTypeList();
    getAreaList();
    getAreaCategoryList();
    listGrade();
    setUseEffectRequired(false);
  }, [useEffectRequired]);

  //api call to get the area type drop down
  const geAreaTypeList = async () => {
    try {
      const response = await areaService.geAreaDropDownList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            console.log(response.data, "respose--->geAreaTypeList");
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  title: element.type_name,
                  id: element.type_code,
                  status: element.status,
                };
              }
            );
            setAreas(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("area response", error);
    }
  };
  //dependency api call on location type onchange
  useEffect(() => {
    getPolicyNameList();
  }, [addAreaData.locationType]);

  useEffect(() => {
    setUnitArea([]);
    getUnitAreaList();
  }, [addAreaData.plant]);

  useEffect(() => {
    setBuildingArea([]);
    getBuildingAreaList();
  }, [addAreaData.unit]);

  //handle table pagination
  const handlePageChange = (newPage: any, pageSize?: number) => {
    setPage(newPage);
    if (pageSize) {
      setPageSize(pageSize);
    }
    setUseEffectRequired(true);
  };

  //api call to get area list
  const getAreaList = async () => {
    try {
      const response = await areaService.getAreaList(
        page,
        pageSize,
        sort,
        order,
        keyWord,
        areaType
      );
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            setTotalPages(response?.totalPages);
            setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                const statusNumber = parseInt(element.type, 10);
                const area_type = areaService.getLocationStatus(statusNumber);

                return {
                  slno: index + 1,
                  id: element.id,
                  area_code: element.area_code,
                  name: element.name,
                  area_type: area_type,
                  status: element.status,
                  qr_image_path: element.qr_image_path,
                };
              }
            );
            setAreaData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setAreaData([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //table filter section
  const handleFilter = (key: string, value: any) => {
    if (key === "AreaType") {
      if (value === "0") {
        setAreaType("8,6");
      } else {
        setAreaType(value);
      }
    } else if (key === "search") {
      setkeyWord(value);
    }
    setUseEffectRequired(true);
  };

  // api call to get area category list
  const getAreaCategoryList = async () => {
    try {
      const response = await areaService.getAreaCategoryList();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  categoryName: element.category_name,
                  status: element.status,
                };
              }
            );
            setCategoryListData(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCategoryListData([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const listGrade = async () => {
    try {
      const response = await gradeTypes.listGradeOptions();
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            // setTotalPages(response?.totalPages);
            // setTotalElements(response?.totalElements);
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  grade: element.grade,
                  id: element.id,
                };
              }
            );
            setGrade(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //open add area modal
  const handleAddClick = () => {
    setShowAddModal(true);
    setModalName("Add Area");
    getPlantAreaList();
  };

  //handle onchange input fields
  const handleAddAreaChange = (e: any) => {
    const { name, value } = e.target;
    setAddAreaData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "policyName") {
      const selectedPolicy = policyName.find((policy) => policy.id === value);
      if (selectedPolicy) {
        setSelectedPolicyDuration(selectedPolicy.duration);
      } else {
        setSelectedPolicyDuration("");
      }
    }
  };

  //get policy name and duration based on the location type selected
  const getPolicyNameList = async () => {
    try {
      const locationTypeNumber = parseInt(addAreaData.locationType, 10);
      const response = await areaService.getPolicyNameList(locationTypeNumber);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  activity_name: element.activity_name,
                  duration: element.duration,
                  id: element.id,
                };
              }
            );
            setPolicyName(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle area validation starts here
  const handlePolicyAddAlert = () => {
    const errors: Errors = {};
    if (!addAreaData.locationName) {
      errors.locationName = "Please enter Area Name";
    }
    if (!addAreaData.plant) {
      errors.plant = "Please Select Plant";
    }
    if (!addAreaData.unit) {
      errors.unit = "Please Select Unit";
    }
    if (!addAreaData.parentArea || addAreaData.parentArea == "0") {
      errors.parentArea = "Please Select Building";
    }
    if (!addAreaData.locationType) {
      errors.locationType = "Please select Location Type";
    }
    if (!addAreaData.areaCode) {
      errors.areaCode = "Please enter Area Code";
    }
    // if (!addAreaData.areaCategory) {
    //   errors.areaCategory = "Please select Area Category";
    // }
    if (addAreaData.id && !addAreaData.reason) {
      errors.reason = "Please enter Reason";
    }
    if (addAreaData.locationType === "6" || addAreaData.locationType === "10") {
      if (!addAreaData.gradeType) {
        errors.gradeType = "Please select Grade Type";
      }
    }

    if (
      addAreaData.locationType === "7" ||
      addAreaData.locationType === "9" ||
      addAreaData.locationType === "12"
    ) {
      if (!addAreaData.capacity) {
        errors.capacity = "Please select Capacity";
      }
    }

    if (
      parseInt(addAreaData.locationType, 10) >= 6 &&
      parseInt(addAreaData.locationType, 10) <= 10
    ) {
      if (!addAreaData.policyName) {
        errors.policyName = "Please select Policy Name";
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setShowAddAreaAlert(true);
    setErrors({});
  };

  //clear modal on close
  const addAreaClose = () => {
    setShowAddModal(false);
    setErrors({});
    setAddAreaData({
      plant: "",
      unit: "",
      locationName: "",
      discription: "",
      locationType: "",
      parentArea: "",
      policyName: "0",
      duration: "",
      gradeType: "",
      capacity: "0",
      areaCode: "",
      id: "",
      building: "",
      areaCategory: "",
      reason: "",
    });
    // setParentArea([])
    setPlantArea([]);
    setUnitArea([]);
    setBuildingArea([]);
    setSelectedPolicyDuration("");
  };

  //filter selected id based on the row selected while editing
  const handleEditClick = (id: string) => {
    const filteredUserDetails = areaData.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(",");
    getAreaById(selectedIds);
    setShowAddModal(true);
    setModalName("Edit Area");
    getPlantAreaList();
  };

  //get area details by id on clicking edit button
  const getAreaById = async (Id: string) => {
    try {
      const response = await areaService.getAreaId(Id);
      if (response.status && response.statusCode === 200) {
        setAddAreaData({
          plant: response.data.area_details.plant_id,
          unit: response.data.area_details.unit_id,
          building: response.data.area_details.block_id,
          locationName: response.data.name,
          discription: response.data.description,
          locationType: response.data.type,
          parentArea: response.data.parent_area,
          policyName: response.data.policy_id,
          gradeType: response.data.grade,
          capacity: response.data.capacity,
          areaCode: response.data.area_code,
          duration: "",
          id: response.data.id,
          areaCategory: response.data.area_category,
          reason: response.data.reason,
        });
        setSelectedPolicyDuration(response.data.policy_duration);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //handle add area submit api call
  const handleAddAreaSubmit = async () => {
    const dataToSend = {
      id: addAreaData.id,
      user_id: userId,
      plant_id: addAreaData.plant,
      unit_id: addAreaData.unit,
      building_id: addAreaData.building,
      name: addAreaData.locationName,
      description: addAreaData.discription,
      location_type: addAreaData.locationType,
      parent_area: addAreaData.parentArea,
      policy_id: addAreaData.policyName,
      grade: addAreaData.gradeType,
      area_code: addAreaData.areaCode,
      capacity: addAreaData.capacity,
      interface_name: "Area Management",
      area_category: addAreaData.areaCategory,
      reason: addAreaData.reason,
    };

    try {
      const response = await areaService.createArea(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
        getAreaList();
        addAreaClose();
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowAddAreaAlert(false);
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatusChange = (area_id: any, status: string) => {
    setAreaAlert(true);
    setAreaId(area_id);
    if (status === "active") {
      setUpdatedStatus("inactive");
    } else {
      setUpdatedStatus("active");
    }
  };

  //change area status api call
  const handleStatusChangeSubmit = async () => {
    try {
      const response = await areaService.changeAreaStatus(
        areaId,
        updatedStatus,
        "Area Management"
      );

      if (response.status && response.statusCode === 200) {
        setToastType("success");
        setToastMessage(response.message);
      } else {
        setToastType("error");
        setToastMessage(response.message);
      }
      setShowToast(true);
      setUseEffectRequired(true);
      setUpdatedStatus("");
      setAreaId("");
      setAreaAlert(false);
    } catch (error) {
      console.error(error);
    }
  };

  //handle single and multi QR download button click
  const handleQrDownload = async (slno: string[], type: string) => {
    const filteredUserDetails = areaData.filter((item: any) =>
      slno.includes(item.slno)
    );
    const selectedIds = filteredUserDetails
      .map((item: any) => item.id)
      .join(",");
    // const selectedSerielNo = filteredUserDetails.map((item: any) => item.serial_number);
    setSelectedSlnoLen(slno.length);
    if (type === "single") {
      setShowGenerateQrAlert(true);
      setImageId(selectedIds);
    } else if (type === "multi") {
      setModalShow(true);
      setSelectedSerielNumbers(filteredUserDetails);
    }
  };

  //generate qr
  const handleGenerateQr = () => {
    setShowGenerateQrAlert(false);
    const imageUrl = `${baseUrl}/location/download_area_qr/?area_id=${imageId}`;
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    link.click();
  };

  //create grid for qr section
  const createGrid = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {selectedSerielNumbers.map((cellIndex: any, index: number) => (
          <div
            key={index}
            style={{
              border: "0.5px solid grey",
              margin: "15px 15px",
              alignItems: "center",
            }}
          >
            <img
              src={cellIndex?.qr_image_path}
              alt={`Image ${cellIndex?.area_code}`}
              style={{
                width: `${cellWidth}cm`,
                height: `${cellWidth}cm`,
                pageBreakInside: "avoid",
              }}
            />
            <p
              style={{
                fontSize: `${cellWidth + 10}px`,
                fontWeight: "800",
                margin: "2px",
                textAlign: "center",
              }}
            >
              {cellIndex?.name}
            </p>
          </div>
        ))}
      </div>
    );
  };

  //handle download qr button
  const handleDownloadBtn = () => {
    const content: any = document.getElementById("innerDiv");
    const pri: any = window.open("", "", "height=600,width=800");
    pri.document.write(content.innerHTML);
    pri.onafterprint = () => {
      pri.close();
      setModalShow(false);
    };
    pri.print();
  };

  //api call for plant area list in add modal
  const getPlantAreaList = async () => {
    try {
      const response = await areaService.getAddAreaType("1");
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  name: element.name,
                  id: element.id,
                  type: element.type,
                  status: element.status,
                };
              }
            );
            setPlantArea(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //api call for unit area list in add modal
  const getUnitAreaList = async () => {
    try {
      if (addAreaData.plant !== "") {
        const response = await areaService.getHeirarchyType(
          "2",
          addAreaData.plant
        );
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map(
                (element: any, index: number) => {
                  return {
                    slno: index + 1,
                    name: element.name,
                    id: element.id,
                    type: element.type,
                    status: element.status,
                  };
                }
              );
              setUnitArea(rowData);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  //api call for building area list in add modal
  const getBuildingAreaList = async () => {
    try {
      if (addAreaData.unit !== "") {
        const response = await areaService.getHeirarchyType(
          "3",
          addAreaData.unit
        );
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map(
                (element: any, index: number) => {
                  return {
                    slno: index + 1,
                    name: element.name,
                    id: element.id,
                    type: element.type,
                    status: element.status,
                  };
                }
              );
              setBuildingArea(rowData);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // function to sort table
  const handleSort = (key: string, value: boolean) => {
    setSort(key);
    if (value) {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setUseEffectRequired(true);
  };

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return {
        //           // slno: index + 1,
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t("areaManagement.area_location_management")} />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <div className="exceptionHandlingTab">
        <button
          onClick={() => setTab("1")}
          className={tab === "1" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("areaManagement.area")}
        </button>
        <button
          onClick={() => setTab("2")}
          className={tab === "2" ? "colTabBtnSel" : "colTabBtn"}
        >
          {t("areaManagement.location")}
        </button>
      </div>
      {tab === "1" && (
        <div className="me-3" style={{ marginTop: "10px" }}>
          <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
              tableHead={t("areaManagement.areas")}
              data={areaData}
              columns={columns}
              isEditable={permission?.includes("edit_area")}
              totalElements={totalElements}
              isActionVisible={permission?.includes("edit_area")}
              isViewVisible={false}
              isSeachable={true}
              addButton={permission?.includes("add_area")}
              areaList={areas}
              addButtonText={t("areaManagement.add_area")}
              onFilterClick={handleFilter}
              onSortClick={handleSort}
              onAddClick={handleAddClick}
              isToggleStatus={permission?.includes("edit_area")}
              onStatusChange={handleStatusChange}
              isQrDownloadable={true}
              onEditClick={handleEditClick}
              onQrDownloadClick={handleQrDownload}
            />
          </div>
          <div>
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      )}
      {tab === "2" && <LocationManagement />}

      {/*modal for Add/edit Area */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAddModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => addAreaClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
          <div className="selectWrap">
            <div className="form-group my-1">
              <label>
                {t("areaManagement.plant")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="plant"
                value={addAreaData.plant}
                name="plant"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {plantArea.map((plant) => (
                  <option key={plant.id} value={plant.id}>
                    {plant.name}
                  </option>
                ))}
              </select>
              {errors.plant && <p className="errorText">{errors.plant}</p>}
            </div>

            <div className="form-group my-1">
              <label>
                {t("areaManagement.unit")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="unit"
                value={addAreaData.unit}
                name="unit"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {unitArea.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>
              {errors.unit && <p className="errorText">{errors.unit}</p>}
            </div>

            <div className="form-group my-1">
              <label>
                {t("areaManagement.block")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="parentArea"
                value={addAreaData.parentArea}
                name="parentArea"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {BuildingArea.map((parentArea) => (
                  <option key={parentArea.id} value={parentArea.id}>
                    {parentArea.name}
                  </option>
                ))}
              </select>
              {errors.parentArea && (
                <p className="errorText">{errors.parentArea}</p>
              )}
            </div>
          </div>

          <div className="selectWrap">
            <div className="form-group my-1">
              <label>
                {t("areaManagement.area_types")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="locationType"
                value={addAreaData.locationType}
                name="locationType"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {areas.map((area) => (
                  <option key={area.id} value={area.id}>
                    {area.title}
                  </option>
                ))}
              </select>
              {errors.locationType && (
                <p className="errorText">{errors.locationType}</p>
              )}
            </div>

            <div className="form-group my-1">
              <label htmlFor="">
                {t("areaManagement.area_name")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="locationName"
                value={addAreaData.locationName}
                name="locationName"
                type="text"
                placeholder="Area Name"
                className="placeholder-style"
                onChange={handleAddAreaChange}
              />
              {errors.locationName && (
                <p className="errorText">{errors.locationName}</p>
              )}
            </div>

            <div className="form-group my-1">
              <label>{t("areaManagement.description")}</label>
              <br />
              <input
                id="discription"
                value={addAreaData.discription}
                name="discription"
                type="text"
                placeholder="Description"
                className="placeholder-style"
                onChange={handleAddAreaChange}
              />
            </div>
          </div>

          <div className="selectWrap">
            <div className="form-group my-1">
              <label>{t("areaManagement.policy_name")} <span style={{ color: "red" }}>*</span></label>
              <br />
              <select
                id="policyName"
                value={addAreaData.policyName}
                name="policyName"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {policyName.map((policy) => (
                  <option key={policy.id} value={policy.id}>
                    {policy.activity_name}
                  </option>
                ))}
              </select>
              {errors.policyName && (
                <p className="errorText">{errors.policyName}</p>
              )}
            </div>

            <div className="form-group my-1">
              <label>{t("areaManagement.duration")}</label>
              <br />
              <input
                id="duration"
                value={selectedPolicyDuration}
                name="duration"
                type="text"
                readOnly
                placeholder="Duration"
                className="placeholder-style"
                //  onChange={handleAddAreaChange}
              />
            </div>

            <div className="form-group my-1">
              <label>
                {t("areaManagement.area_code")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                id="areaCode"
                value={addAreaData.areaCode}
                name="areaCode"
                type="text"
                placeholder="Area Code"
                className="placeholder-style"
                onChange={handleAddAreaChange}
              />
              {errors.areaCode && (
                <p className="errorText">{errors.areaCode}</p>
              )}
            </div>
          </div>
          <div className="selectWrap-new">
            {/* {(addAreaData.locationType === "6" || addAreaData.locationType === "10") && ( */}
            <div className="form-group my-1 mx-3 px-2">
              <label htmlFor="">
                {t("areaManagement.grade")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="gradeType"
                value={addAreaData.gradeType}
                name="gradeType"
                onChange={handleAddAreaChange}
              >
                <option value="">select</option>
                {grade.map((obj, index) => (
                  <option key={index} value={obj?.id}>
                    {obj?.grade}
                  </option>
                ))}
              </select>
              {errors.gradeType && (
                <p className="errorText">{errors.gradeType}</p>
              )}
            </div>
            {/* )} */}

            {/* <div className="form-group my-1 mx-5 px-1">
                    <label htmlFor="">{t('areaManagement.areaCategory')}<span style={{ color: 'red' }}>*</span></label>
                    <br />
                    <select
                      id="areaCategory"
                      value={addAreaData.areaCategory}
                      name="areaCategory"
                      onChange={handleAddAreaChange} 
                    >
                      <option value="">select</option>
                      {categoryListData.map((categoryList: any) => (
                          <option key={categoryList.id} value={categoryList.id}>
                            {categoryList.categoryName}
                          </option>
                      ))}
                    </select>
                    {errors.areaCategory && <p className="errorText">{errors.areaCategory}</p>}
                  </div> */}

            {(addAreaData.locationType === "7" ||
              addAreaData.locationType === "9" ||
              addAreaData.locationType === "12") && (
              <div className="form-group my-1 mx-3 px-2">
                <label>{t("areaManagement.capacity")}</label>
                <br />
                <input
                  id="capacity"
                  value={addAreaData.capacity}
                  name="capacity"
                  type="text"
                  placeholder="Capacity"
                  className="placeholder-style"
                  onChange={handleAddAreaChange}
                />
                {errors.capacity && (
                  <p className="errorText">{errors.capacity}</p>
                )}
              </div>
            )}
            <div className="form-group my-1"></div>
          </div>

          <div className="selectWrap-new">
            {addAreaData.id && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={addAreaData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleAddAreaChange}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
            <div className="form-group my-1 " style={{ visibility: "hidden" }}>
              <label htmlFor="">{t("userManagement.reason")}</label>
              <br />
              <input
                id="reason1"
                // value={addUserData.reason}
                name="reason1"
                type="text"
                placeholder="Reason"
                className="placeholder-style"
                // onChange={handleAddUserChange}
              />
            </div>
          </div>

          <div className="bottomArea">
            <button onClick={() => addAreaClose()} className="modalCancelBtn">
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handlePolicyAddAlert()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      {/*modal for Generate Area QR*/}
      <Modal
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Header closeButton onClick={() => setModalShow(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("buttons.download_qr")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selected {selectedSlnoLen} Areas for download QR</p>
          <div className="selectWrap">
            <div className="form-group my-1">
              <label>{t("areaManagement.cell_width")}</label>
              <br />
              <input
                type="number"
                id="cellWidth"
                value={cellWidth}
                min="1"
                onChange={(e) => setCellWidth(parseInt(e.target.value))}
              />
            </div>
          </div>
          <div
            id="innerDiv"
            hidden
            style={{
              border: "1px solid black",
              padding: "5px",
              margin: "5px",
              overflow: "scroll",
              height: "80vh",
            }}
          >
            {createGrid()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleDownloadBtn()}>
            {t("buttons.submit")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Alert modal for download QR */}
      <AlertModal
        show={showGenerateQrAlert}
        onHide={() => setShowGenerateQrAlert(false)}
        title="Alert"
        message={`Download QR`}
        onConfirm={handleGenerateQr}
      />

      {/* Alert modal for Add/edit Area */}
      <AlertModal
        show={showAddAreaAlert}
        onHide={() => setShowAddAreaAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleAddAreaSubmit}
      />

      {/* Alert modal for change Area status */}
      <AlertModal
        show={areaAlert}
        onHide={() => setAreaAlert(false)}
        title="Alert"
        message={`Change Area Status`}
        onConfirm={handleStatusChangeSubmit}
      />
    </div>
  );
};

export default AreaManagement;
