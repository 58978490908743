import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Chart from "../partials/chart";
import { Bar, Line, Pie } from 'react-chartjs-2';
import DownloadPdf from "../../../../widgets/downloadPdf";
import { trendAnalytics } from "../../../../service/trendAnalytics";
import { FiFilter } from "react-icons/fi";
import { useTranslation } from "react-i18next";


const TrendAnalytics: React.FC = () => {
  const { bulletId } = useParams<{ bulletId: string }>();
  const { t } = useTranslation();

  const [xaxisRange, setXaxisRange] = useState(bulletId)
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedGraph, setSelectedGraph] = useState<string>("Trends");
  // const [trendAnalyticsData, setTrendAnalyticsData] = useState([]);
  const [barLabels, setbarLabels] = useState<string []>([]);
  // const [NewLabels, setNewLabels] = useState([])
  const [areaDropDown, setareaDropDown] = useState<Array<any>>([])
  const [seriesTypes, setSeriesTypes] = useState<Array<any>>([])
  const [activityTypes, setActivityTypes] = useState<Array<any>>([])
  const [locationDropDown, setlocationDropDown] = useState<Array<any>>([])
  const[type , setType] = useState<Array<any>>([]);  
  const[genus , setGenus] = useState<Array<any>>([]); 
  const[species , setSpecies] = useState<Array<any>>([]);  
  const[strain , setStrain] = useState<Array<any>>([]); 
  const [shift, setShift] = useState<Array<any>>([])
  const [pieChartData, setPieChartData] = useState<Array<any>>([])
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)

  const graphColors = ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6", "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"];
  const [formData, setFormData] = useState({
    areaDropdownValue: '',
    locationDropdownValue: '',
    trendTypeSelect: bulletId || '',
    activitySelect: '',
    shiftDetails: '',
    seriesSelect: 'organism_type',
    organismTypeSelect: '',
    genusSelect: '',
    speciesSelect: '',
    strainSelect: '',
    graphTypeSelect: 'bar',
    fromDate: '',
    toDate: ''
  });

  type LabelType = string[];
  type TrendAnalyticsDataType = number[][];
  const [newLabels, setNewLabels] = useState<LabelType>([]);
  const [trendAnalyticsData, setTrendAnalyticsData] = useState<TrendAnalyticsDataType>([]);

  useEffect(() => {
    getTrendAnalyticsData();
    if (bulletId === "area") {
      setSelectedArea("Area");        
    } else if (bulletId === "location") {
      setSelectedArea("Location")
    } else if (bulletId === "operator") {
      setSelectedArea("Operator");
    } else if (bulletId === "microbiologist") {
      setSelectedArea("Microbiologist");  
    } else if (bulletId === "day") {
      setSelectedArea("Day");
    } else if (bulletId === "week") {
      setSelectedArea("Week");
    } else if (bulletId === "month") {
      setSelectedArea("Month");
    } else if (bulletId === "year") {
      setSelectedArea("Year");
    } else if (bulletId === "quarter") {
        setSelectedArea("Quarter");           
    } else {
      setSelectedArea("");
    }
    listTypeOptions();
    listShiftOptions();
    listSeriesTypes();
    listActivityTypes();
    if (bulletId != "location"){
      listAreaType();
    } else if(bulletId == "location" ){
      listLocationType("0")
    }
  }, [bulletId]);


  interface ChartData {
    labels: string[];
    datasets: {
      label?: string;
      data: number[];
      backgroundColor: any;
      borderColor?: string;
      borderWidth?: number;
      fill?: boolean; 
    }[];
  }


let data: ChartData 

if (formData.graphTypeSelect === "pie") {
   data = {
    labels: barLabels,
    datasets: trendAnalyticsData?.map((counts, index) => ({
      data: pieChartData,
      backgroundColor: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      borderWidth: 0,
      borderColor: "none"
    }))
  };
} else {
  data = {
    labels: newLabels,
    datasets: trendAnalyticsData?.map((counts, index) => ({
      label: barLabels[index],
      data: counts,
      backgroundColor: graphColors[index],
      borderColor: graphColors[index],
      borderWidth: 1,
      fill: formData.graphTypeSelect === "area"
    }))
  };
}


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value ,"name value")
    setFormData({
      ...formData,
      [name]: value
    });
  
    if (name === "areaDropdownValue" && bulletId != "location") {
      if (value !== '0') {
        listLocationType(value);
      }else {
        setlocationDropDown([]);
      }
    }

    if (name === "fromDate") {
      const startDate = new Date(value);
      const maxEndDate = new Date(startDate);
      maxEndDate.setDate(startDate.getDate() + 7); // Add 7 days to start date

      // Format the maxEndDate as YYYY-MM-DDTHH:MM for input type datetime-local
      const formattedMaxEndDate = maxEndDate.toISOString().slice(0, 16);
      setFormData((prevData) => ({
        ...prevData,
        toDate: formattedMaxEndDate
      }));
    }

    // if (name === "toDate") {
    //   const startDate = new Date(value);
    //   const maxEndDate = new Date(startDate);
    //   maxEndDate.setDate(startDate.getDate() - 7); // Add 7 days to start date

    //   // Format the maxEndDate as YYYY-MM-DDTHH:MM for input type datetime-local
    //   const formattedMaxEndDate = maxEndDate.toISOString().slice(0, 16);
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     fromDate: formattedMaxEndDate
    //   }));
    // }

    if(name === "organismTypeSelect"){
      handleOrganismTypeChange(value); 
    }
    if(name === "genusSelect"){
      handleGenusTypeChange(value); 
    }
    if(name === "speciesSelect"){
      handleSpeciesTypeChange(value); 
    }
  };
  console.log(formData , "form dataaaaa")
 
  interface ChartType {
    label: string;
    component: any;
    backgroundColors: string[];
    borderColors: string[];
    stack?: boolean;
    fill?: boolean;
    selectedOption?: string;
    selectedGraph?: string;
    graphNameType?:string[];
    selectedGraphName?: string;
    xAxisSelector?:string[];
  }

  interface ChartTypes {
    [key: string]: ChartType;
  }

  const chartTypes: ChartTypes = {
    bar: {
      label: 'Bar Chart',
      component: Bar,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
    },
    line: {
      label: 'Line Chart',
      component: Line,
      backgroundColors: ['rgba(53, 162, 235, 0)', 'rgba(23, 152, 225, 0)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
    },
    area: {
      label: 'Area Chart',
      component: Line,
      backgroundColors: ['rgba(75, 192, 192, 0.5)', 'rgba(54, 162, 235, 0.5)'],
      borderColors: ['rgb(75, 192, 192)', 'rgb(54, 162, 235)'],
    },
    stackedBar: {
      label: 'Stacked Bar Chart',
      component: Bar,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)'],
      stack: true,
    },
    pie: {
      label: 'Pie Chart',
      component: Pie,
      backgroundColors: ['rgba(53, 162, 235, 0.5)', 'rgba(23, 152, 225, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(255, 205, 86, 0.5)', 'rgba(255, 99, 132, 0.5)'],
      borderColors: ['rgb(53, 162, 235)', 'rgb(23, 152, 225)', 'rgb(75, 192, 192)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)'],
    }, 
  };

   //list area types dropdown 
   const listAreaType = async () => {
    try {
      const response = await trendAnalytics.listAreaType();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                    type: element.type,
                };
              });
              setareaDropDown(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //list series types
  const listSeriesTypes = async () => {
    try {
      const response = await trendAnalytics.listSeriesTypes();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    label: element.series_type_label,
                    value: element.series_type_value,
                 
                };
              });
              setSeriesTypes(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //list activity types
  const listActivityTypes = async () => {
    try {
      const response = await trendAnalytics.listActivityTypes();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    label: element.name,
                    value: element.id,
                 
                };
              });
              setActivityTypes(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  //list location types dropdown 
  // const getTrendAnalyticsData = async () => {
  //   try {
  //     const response = await trendAnalytics.getTrendAnalyticsData(formData);
  //     if (response.status && response.statusCode === 200 && response.data.graph_details) {
  //       const newLabel = response.data.graph_details.map((obj: any) => obj.name);
  //       setNewLabels(newLabel);
        
  //       let organismTypeCounts: any = {};
  
  //       // Loop through each graph detail based on seriesType
  //       response.data.graph_details?.forEach((graphDetail: any) => {
  //         // Check the seriesType to determine the data structure to use
  //         let dataStructure = graphDetail.graph_data;
  //         let seriesType = formData.seriesSelect;
  
  //         if (dataStructure) {
  //           dataStructure.forEach((type: any) => {
  //             let typeName;
  //             // Determine the key based on the seriesType
  //             if (seriesType === "organism_type") {
  //               typeName = type.organism_type;
  //             } else if (seriesType === "genus") {
  //               typeName = type.genus_name;
  //             } else if (seriesType === "species") {
  //               typeName = type.name;
  //             } else if (seriesType === "strain") {
  //               typeName = type.name;
  //             } else if(seriesType === "total_count") {
  //               typeName = type.label;
  //             }

  //             if (typeName in organismTypeCounts) {
  //               // If it exists, push total_count to the existing array
  //               organismTypeCounts[typeName].push(type.total_count);
  //             } else {
  //               // If it doesn't exist, initialize a new array with total_count
  //               organismTypeCounts[typeName] = [type.total_count];
  //             }
  //           });
  //         } else {
  //           // If the data structure doesn't exist, initialize counts for all types with zero values
  //           Object.keys(organismTypeCounts).forEach(type => {
  //             organismTypeCounts[type].push(0);
  //           });
  //         }
  //       });
         
  //       setbarLabels(Object.keys(organismTypeCounts));
  //       setTrendAnalyticsData(Object.values(organismTypeCounts));
  //       const sumOfKeys = Object.keys(organismTypeCounts).map(key => {
  //         const sum = organismTypeCounts[key].reduce((acc : number, curr: number) => acc + curr, 0);
  //         return sum;
  //       });
  //       setPieChartData(sumOfKeys)
  
  //     }else{
  //       setbarLabels([])
  //       setTrendAnalyticsData([])
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // }
   

  const getTrendAnalyticsData = async () => {
    try {
        const response = await trendAnalytics.getTrendAnalyticsData(formData);
        if (response.status && response.statusCode === 200 && response.data.graph_details) {
            const newLabels: string[] = [];
            const organismTypeCounts: { [key: string]: number[] } = {};

            // Loop through each graph detail
            response.data.graph_details.forEach((graphDetail: any) => {
                if (graphDetail.graph_data) {
                    const typeName = graphDetail.name;
                    const dataStructure = graphDetail.graph_data;

                    newLabels.push(typeName);

                    // Loop through each type in graph data
                    dataStructure.forEach((type: any) => {
                      let label;
                      switch(formData.seriesSelect) {
                          case 'organism_type':
                              label = type.organism_type;
                              break;
                          case 'genus':
                              label = type.genus_name;
                              break;
                          case 'species':
                          case 'strain':
                              label = type.name;
                              break;
                          case 'total_count':
                              label = type.label;
                              break;
                          default:
                              label = type.organism_type; // Default label
                      }

                        // Check if the label exists in organismTypeCounts
                        if (label in organismTypeCounts) {
                            // Push total_count to the existing array
                            organismTypeCounts[label].push(type.total_count);
                        } else {
                            // Initialize a new array with total_count
                            organismTypeCounts[label] = [type.total_count];
                        }
                    });
                }
            });
         
           setNewLabels(newLabels);
            setbarLabels(Object.keys(organismTypeCounts));
            setTrendAnalyticsData(Object.values(organismTypeCounts));

            // Calculate sum for each label
            const sumOfKeys = Object.keys(organismTypeCounts).map((key) => {
                const sum = organismTypeCounts[key].reduce((acc: number, curr: number) => acc + curr, 0);
                return sum;
            });
            setPieChartData(sumOfKeys);
        } else {
            setNewLabels([]);
            setbarLabels([]);
            setTrendAnalyticsData([]);
            setPieChartData([]);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};


  const listLocationType = async (parent_area: string) => {
    try {
      const response = await trendAnalytics.listLocationType(parent_area);
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                    type: element.type,
                };
              });
              setlocationDropDown(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

      //api call to organism type
    const listTypeOptions = async () => {
      try {
        const response = await trendAnalytics.listTypeOptions();  
        if (response.status && response.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            organism_type: element.organism_type
          }));
          setType(rowData);
        } else {
          setType([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    //api call to get genus
    const handleOrganismTypeChange = async (selectedTypeId: string) => {
      try {
        const response = await trendAnalytics.listGenusOptions(selectedTypeId);
        if (response.status && response.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            genus_name: element.genus_name
          }));
          setGenus(rowData);
        } else {
          setGenus([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleGenusTypeChange = async (selectedTypeId: string) => {
      try {
        const response = await trendAnalytics.listSpeciesOptions(selectedTypeId);
        if (response.status && response.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            name: element.name
          }));
          setSpecies(rowData);
        } else {
          setSpecies([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleSpeciesTypeChange = async (selectedTypeId: string) => {
      try {
        const response = await trendAnalytics.listStrainOptions(selectedTypeId);
        if (response.status && response.statusCode === 200) {
          const rowData = response.data.map((element:any, index:any) => ({
            sl_no: index + 1,
            id: element.id,
            name: element.name
          }));
          setStrain(rowData);
        } else {
          setStrain([]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

   //list shift dropdown 
   const listShiftOptions = async () => {
    try {
      const response = await trendAnalytics.listShiftOptions();
      if(response.status && response.statusCode === 200){
        try {
            if ( Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map((element: any, index: number) => {
  
                return { 
                    value: element.id,
                    label: element.name,
                };
              });
              setShift(rowData);

            }
          } catch (error) {
            console.log(error); 
          }
      }
    } catch (error) {
      console.error('Plate response', error);
    }
  }

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate =
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
        `${date.getDate().toString().padStart(2, '0')} ` +
        `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    return formattedDate;
};

  const handleApplyClick =() =>{
    getTrendAnalyticsData();
  }

  const handleCancel = () => {
    const initialFormData = {
      areaDropdownValue: '',
      locationDropdownValue: '',
      trendTypeSelect: 'area',
      activitySelect: '',
      shiftDetails: '',
      seriesSelect: 'organism_type',
      organismTypeSelect: '',
      genusSelect: '',
      speciesSelect: '',
      strainSelect: '',
      graphTypeSelect: 'bar',
      fromDate: '',
      toDate: ''
    };
    setFormData(initialFormData);
    setlocationDropDown([])
  }
  
  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader={t('dashboard.trendAnalytics')} />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item dashboard-hover">
              <Link to="/analytics-dashboard">{t('dashboard.analyticalDashboard')}</Link>
            </li>
            <li className="breadcrumb-item dashboard-hover" aria-current="page">
              <Link to="/inner-landing/Trend">{t('dashboard.trendDashboard')}</Link> 
            </li>
            <li className="breadcrumb-item active selectedArea" aria-current="page">
              {selectedArea}
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex">
          <div>
              <button onClick={() => setShowAdvanceFilter(!showAdvanceFilter)} className="filterBtn">{t('buttons.filter')} <FiFilter /></button>
          </div>
          <DownloadPdf divId="dashWrap" reportHead="Trend Report" />
        </div>
      </nav>

      <div>
      <div className="d-flex justify-content-around">

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_trend_type')} <span style={{ color: 'blue' }}>*</span>:</label>
          <select name="trendTypeSelect" className='areaTypeSelect' value={formData.trendTypeSelect} onChange={handleChange} >
            <option value="">Select</option>
            <option value="area" >Area</option>
            <option value="location" >Location</option>
            <option value="operator" >Operator</option>
            {/* <option value="microbiologist" >Microbiologist</option> */}
          </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_series')} <span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="seriesSelect" value={formData.seriesSelect} onChange={handleChange}>
          {seriesTypes?.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))} 
          </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_graph_type')} <span style={{ color: 'red' }}>*</span>:</label>
          <select className='areaTypeSelect' name="graphTypeSelect" value={formData.graphTypeSelect} onChange={handleChange}>
            {Object.keys(chartTypes).map((type) => (
              <option key={type} value={type}>
                {chartTypes[type].label}
              </option>
            ))}
          </select>
        </div>

        {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Period <span style={{ color: 'blue' }}>*</span>:</label>
          <select name="trendTypeSelect" className='areaTypeSelect' value={formData.trendTypeSelect} onChange={handleChange}>
            <option value="">Select</option>
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
            <option value="quarter">Quaterly</option>
            <option value="year">Yearly</option>
        </select>
        </div> */}

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_activity')} <span style={{ color: 'red' }}>*</span>:</label>
          <select name="activitySelect" className='areaTypeSelect' value={formData.activitySelect} onChange={handleChange}>
            <option value="">Select</option>
              {activityTypes?.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
              ))}
          </select>
        </div>

        <div className="d-flex" style={{marginRight: "4%"}} >
         
        <div className="select-container analyticFilterDate " 
            style={{display: "flex", flexDirection: "column", marginRight:"15%"}}>
              <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.from_date')}:</label>
              <input
              id="selectedDate"
              className="form-control"
              value={formatDateTime(formData.fromDate)}
              name="fromDate"
              type="datetime-local"
              onChange={handleChange}
        />
        </div>

        <div className="select-container analyticFilterDate" 
            style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.to_date')}:</label>
            <input
              id="selectedDate"
              className="form-control"
              value={formatDateTime(formData.toDate)}
              name="toDate"
              type="datetime-local"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div hidden={!showAdvanceFilter}>
      <div className="d-flex justify-content-around mt-3">

         {bulletId != "location" && (
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_area')}:</label>
          <select name="areaDropdownValue" className='areaTypeSelect' onChange={handleChange } value={formData.areaDropdownValue}>
          <option value="">Select</option>
            {areaDropDown?.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
        )}

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_location')}:</label>
          <select className='areaTypeSelect' name="locationDropdownValue" onChange={handleChange} value={formData.locationDropdownValue}>
          <option value="">Select</option>
            {locationDropDown?.map((type) => (
              <option key={type.value} value={type.value}>
              {type.label}
            </option>
            ))}
          </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_shift')}:</label>
          <select name="shiftDetails" className='areaTypeSelect' value={formData.shiftDetails} onChange={handleChange}>
            <option value="">Select</option>
            {shift?.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
             ))} 
          </select>
        </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_organism_type')}:</label>
            <select className='areaTypeSelect' name="organismTypeSelect" value={formData.organismTypeSelect} onChange={handleChange}>
              <option value="">Select</option>
              {type?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.organism_type}
                </option>
              ))} 
            </select>
          </div>

        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_genus')}:</label>
          <select className='areaTypeSelect' name="genusSelect" value={formData.genusSelect} onChange={handleChange}>
            <option value="">Select</option>
            {genus?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.genus_name}
              </option>
            ))}
          </select>
        </div>
        
        <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
          <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_species')}:</label>
          <select className='areaTypeSelect' name="speciesSelect" value={formData.speciesSelect} onChange={handleChange}>
            <option value="">Select</option>
            {species?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
      
      </div>

      <div className="d-flex justify-content-between mt-3">
        <div className="select-container" style={{display: "flex", flexDirection: "column", padding:"0 2%"}}>
            <label htmlFor="" style={{color: "black", fontSize: "12px"}}>{t('common.select_strain')}:</label>
            <select className='areaTypeSelect' name="strainSelect" value={formData.strainSelect} onChange={handleChange}>
              <option value="">Select</option>
              {strain?.map((type) => (
                 <option key={type.id} value={type.id}>
                 {type.name}
               </option>
               ))} 
            </select>
          </div>

      {/* <div className="select-container" style={{display: "flex", flexDirection: "column"}}>
        <label htmlFor="" style={{color: "black", fontSize: "12px"}}>Select Series <span style={{ color: 'red' }}>*</span>:</label>
        <select className='areaTypeSelect' name="seriesSelect" value={formData.seriesSelect} onChange={handleChange}>
        {seriesTypes?.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))} 
        </select>
      </div> */}


         


        {/* <div className="d-flex " style={{width:"80%"}}>
           <div className="select-container analyticFilterDate ms-1" 
            style={{display: "flex", flexDirection: "column"}}>
              <label htmlFor="" style={{color: "black", fontSize: "12px"}}>From Date:</label>
              <input
              id="selectedDate"
              className="form-control"
              value={formatDateTime(formData.fromDate)}
              name="fromDate"
              type="datetime-local"
              onChange={handleChange}
        />
          </div> 

          
        </div> */}

          {/* <div className="my-3">
            <button className="analyticalFilterClose" title="Click here to Reset Filter"  onClick={handleCancel}>Cancel</button>
            <button className="analyticalFilterApply" title="Click here to Apply Filter" onClick={handleApplyClick}>Apply</button>
          </div> */}
      </div>
      </div>

      <div className="mt-3 mx-2 d-flex justify-content-end" >
        <button className="analyticalFilterClose" title="Click here to Reset Filter"  onClick={handleCancel}>{t('buttons.cancel')}</button>
        <button className="analyticalFilterApply" title="Click here to Apply Filter" onClick={handleApplyClick}>{t('buttons.apply')}</button>
      </div>


      <div className="row mx-2 mt-5 " id="dashWrap" style={{"display":"flex", "justifyContent":"center"}}>
        <div className="col-9">
          <div className="">
            <Chart
              chartType={formData.graphTypeSelect}
              data={data}
              selectedOption={selectedArea}
              selectedGraph={selectedGraph}
              selectedGraphName={formData.seriesSelect}
              activitySelect= {formData.activitySelect}
              xaxisRange={xaxisRange}
            />
          </div>
        </div>
        <div className="col-6">
        </div>
      </div>
      </div>
    </div>
  );
};

export default TrendAnalytics;
