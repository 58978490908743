import React, {useState ,useEffect} from 'react'
import { Modal } from 'react-bootstrap'
import { TfiClose } from 'react-icons/tfi';
import { FaChevronDown } from "react-icons/fa";
import { BsArrowRepeat } from "react-icons/bs";
import { areaService } from '../../../../service/areaServices';
import { policyService } from '../../../../service/policyServices';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';


interface AddScheduleModalProps {
    showAddModal: boolean;
    setShowAddScheduleAlert: () => void;
    setShowAddModal: () => void;
    modalName: string;
    endTime: string;
    selectedDays: string;
    scheduleId: string;
    fromDate: string;
    toDate: string;
    setFromDate: (date : string) => void;
    setToDate: (date : string) => void;
    setEndTime: (time: string) => void;
    setSelectedDays: (days: string) => void;
    setSelectedScheduleId: (id: string) => void;

    setAddScheduleData: React.Dispatch<React.SetStateAction<{ 
      id:string,
      policyScheduleName: string,
      exposureArea: string,
      exposurePoint: string,
      fromTime: string,
      fromDate: string,
      toDate: string,
      reason: string,
    }>>;
    addScheduleData: { 
      policyScheduleName: string,
      exposureArea: string,
      exposurePoint: string,
      fromTime: string,
      fromDate: string,
      toDate: string,
      reason: string,
      id: string
    }
}

const AddScheduleModal: React.FC<AddScheduleModalProps> = ({
    showAddModal,
    setShowAddScheduleAlert,
    setShowAddModal,
    scheduleId,
    addScheduleData,
    setAddScheduleData,
    modalName,
    endTime,
    selectedDays,
    setEndTime,
    setSelectedDays,
    setSelectedScheduleId,
}) => {


    interface Errors {
        exposureArea?: string,
        exposurePoint?: string,
        fromTime?: string,
        daysOfWeak?: string,
        lastCalDate?: string,
        nextCalDate?: string,
        reason?: string

      }
      const { t } = useTranslation();
      const [errors, setErrors] = useState<Errors>({});
      const [exposureArea , setExposureArea] =  useState<Array<any>>([]); 
      const [locationPoint , setLocationPoint] =  useState<Array<any>>([]); 
      const [policydetails , setPolicydetails] =  useState<Array<any>>([]); 
      const [selectedOption, setSelectedOption] = useState("");
      const [showDateModal, setShowDateModal] = useState(false);


      const [daysOfWeek, setDaysOfWeek] = useState([
          { name: 'Mon', selected: false, id: '1'},
          { name: 'Tue', selected: false, id: '2' },
          { name: 'Wed', selected: false, id: '3' },
          { name: 'Thu', selected: false, id: '4' },
          { name: 'Fri', selected: false, id: '5' },
          { name: 'Sat', selected: false, id: '6' },
          { name: 'Sun', selected: false, id: '7' },
        ]);

    
      //calculate end time with start time and duration 
      useEffect(() => {
        const calculateEndTime = () => {
          const [startHour, startMinute] = addScheduleData?.fromTime.split(':').map(Number);
          const totalStartMinutes = startHour * 60 + startMinute;
          const totalEndMinutes = totalStartMinutes + Number(policydetails[0]?.duration);
          const endHour = Math.floor(totalEndMinutes / 60);
          const endMinute = totalEndMinutes % 60;
          if (!isNaN(endHour) && !isNaN(endMinute)) {
            const calculatedEndTime = `${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}`;
            setEndTime(calculatedEndTime);
          } else {
            setEndTime("00:00")
          }
        };
    
        calculateEndTime(); 
    
      }, [policydetails[0]?.duration, addScheduleData.fromTime]);

      //api call to get schedule by onchange in schedule id 
      useEffect(() => {
        if(scheduleId) {
          getScheduleById(scheduleId)
        }
      }, [scheduleId])

      //handle add modal input onchange event 
      const handleAddPolicyChange = (e : any) => {
        const { name, value } = e.target;
        setAddScheduleData((prevFormData : any) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      //handle add modal Date input onchange event 
      const handleDateChange = (e : any) => {
        const { name, value } = e.target;
        if (name === 'fromDate' || name === 'toDate') {
          const currentDate = new Date().toISOString().split('T')[0];
          const errorsCopy = { ...errors }; // Copy the errors object
      
          if (name === 'fromDate' && addScheduleData.toDate && value > addScheduleData.toDate) {
            errorsCopy.nextCalDate = 'Select a future date for To Date';
          } else if (name === 'toDate' && value < addScheduleData.fromDate) {
            errorsCopy.nextCalDate = 'Select a future date for To Date';
          } else {
            setAddScheduleData((prevFormData : any) => ({
              ...prevFormData,
              [name]: value,
            }));
          }
      
          // Update the errors state
          setErrors(errorsCopy);
        }
      }

      //handle alert section 
      const handlePolicyAddAlert = () => {
        const errors: Errors = {};
          if (!addScheduleData.exposureArea) {
            errors.exposureArea = "Please select Exposure Area";
          }
          if (!addScheduleData.fromTime) {
            errors.fromTime = "Please enter From Time";
          }
          if (!selectedDays) {
            errors.daysOfWeak = "Please select Days of weak";
          }
          if (addScheduleData.id && !addScheduleData.reason) {
            errors.reason = "Please enter Reason";
          }
          
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }
          setShowAddScheduleAlert()
          setErrors({});
      }

      //handle on close modal event
      const addScheduleClose = () => {
        setShowAddModal()
        setErrors({})
        setSelectedScheduleId("")
        setAddScheduleData({
          policyScheduleName: "",
          exposureArea: "",
          exposurePoint: "",
          fromTime: "",
          fromDate: "",
          toDate: "",
          id:"",
          reason: ""
        });
        setLocationPoint([])
        setDaysOfWeek([]);
        setDaysOfWeek([
          { name: 'Mon', selected: false, id: '1'},
          { name: 'Tue', selected: false, id: '2' },
          { name: 'Wed', selected: false, id: '3' },
          { name: 'Thu', selected: false, id: '4' },
          { name: 'Fri', selected: false, id: '5' },
          { name: 'Sat', selected: false, id: '6' },
          { name: 'Sun', selected: false, id: '7' },
        ]);
      }
      
    //get parent area list by area type
    const getParentAreaList = async () => {
        try {  
          const response = await areaService.getParentAreaList([6]);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                        slno: index + 1, 
                        policy_id : element.policy_id,
                        id : element.id,
                        name: element.name,
                    };
                  });
                  setExposureArea(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
    }
   
    // //api call to get exposure point list under area 
    // const getExposurePointList = async (policyId: string) => {
    //     try {  
    //       const response = await areaService.getExposurePointList(policyId);
    //       if(response.status && response.statusCode === 200){
    //         try {
    //             if ( Array.isArray(response.data) && response.data?.length > 0) {
    //               let rowData: any = response.data.map((element: any, index: number) => {
  
    //                 return { 
    //                     slno: index + 1, 
    //                     id : element.id,
    //                     name: element.name,
    //                 };
    //               });
    //               setExposurePoint(rowData);
    //             }
    //           } catch (error) {
    //             console.log(error);
    //           }
    //       }
    //     } catch (error) {
    //       console.error('user response', error);
    //     }
    // }
   
   //api call to get location under area 
    const getLocationPointList = async (id: string) => {
      try {  
        const response = await areaService.getLocationPointList(id);
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  return { 
                      slno: index + 1, 
                      id : element.id,
                      name: element.name,
                  };
                });
                setLocationPoint(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        }
      } catch (error) {
        console.error('user response', error);
      }
  }

    //api call to get policy name and duration based on area 
    const getPolicyList = async (policyId: string) => {
        try {  
          const response = await policyService.getPolicyList(policyId);
          if(response.status && response.statusCode === 200){
            try {
                const responseData = response.data;
                if (responseData) {
                  const rowData = {
                    slno: 1,
                    id: responseData.id,
                    activity_name: responseData.activity_name,
                    duration: responseData.duration,
                    policy_area_type: responseData.policy_area_type
                  };
                  setPolicydetails([rowData]); 
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
    }

    useEffect(() =>{

    getParentAreaList();

    },[])    

    //on change in exposure area to trigger this section 
    useEffect(() =>{
      if(addScheduleData.exposureArea){
        const selectedId = addScheduleData.exposureArea;
        const selectedArea = exposureArea.find((area) => area.id === selectedId);
        // getExposurePointList(selectedArea?.id)
        setLocationPoint([])
        getLocationPointList(selectedArea?.id)
        getPolicyList(selectedArea?.policy_id);
      } else {
        const rowData = {
          slno: 0,
          id: "",
          activity_name: "",
          duration: "",
        };
        setPolicydetails([rowData]);
      }

    },[addScheduleData.exposureArea])  

    //handle on change date function 
    const updateSelectedDays = () => {
        const selectedDays = daysOfWeek
          .filter((day) => day.selected)
          .map((day) => day.id);
          const selectedDaysNew = selectedDays.join(',')
        setSelectedDays(selectedDaysNew);
    };

    const toggleSelection = (index : any) => {
        const updatedDays = [...daysOfWeek];
        updatedDays[index].selected = !updatedDays[index].selected;
        setDaysOfWeek(updatedDays);
        updateSelectedDays();
    };

    //load the modal data on edit click 
    const getScheduleById = async (Id:string) => {
      try {
        const response = await policyService.getScheduleId(Id);
        if(response.status && response.statusCode === 200){
            setAddScheduleData({
              id: response.data.id,
              exposureArea: response.data.exposure_area_id,
              exposurePoint: response.data.location_id,
              fromTime: response.data.from_time,
              fromDate: response.data.start_date,
              toDate: response.data.end_date,
              policyScheduleName: response.data.schedule_name,
              reason: response.data.reason
            });

            const selectedDaysArray = response.data.repeat_days.split(",");
            // Update the 'selected' property for the days present in the selectedDaysArray
            selectedDaysArray.forEach((selectedId: string) => {
              const dayIndex = daysOfWeek.findIndex((day) => day.id === selectedId);
              if (dayIndex !== -1) {
                // If the ID is found, set the 'selected' property to true
                daysOfWeek[dayIndex].selected = true;
              }
            });

            // Call the updateSelectedDays function to update the selected days in the state
            updateSelectedDays();

        }
      } catch (error) {
        console.error('user response', error);
      }
    }
    
    // function to get the current date in the format YYYY-MM-DD
    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

  return (
    <Modal 
              className="top-right-modal-md"
              centered={false}
              show={showAddModal}
          >
            <div>
                <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div onClick={() => addScheduleClose()} className="p-2 mx-3 my-2 ">
                  <TfiClose fill="#FFFFFF" />
                </div>
              </div>
              <div>
                  <div className="selectWrap">
                  <div className="form-group my-1 px-3">
                        <label>{t('scheduleManagement.policyScheduleName')}</label>
                        <br />
                        <input 
                          id="policyScheduleName"
                          value={addScheduleData.policyScheduleName}
                          name="policyScheduleName"
                          type='text'
                          placeholder='Schedule Name'
                          className="placeholder-style"
                          onChange={handleAddPolicyChange} 
                        />
                      </div>

                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="">{t('scheduleManagement.exposureArea')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <select
                        id="exposureArea"
                        value={addScheduleData.exposureArea}
                        name="exposureArea"
                        onChange={handleAddPolicyChange} 
                      >
                        <option value="">--select--</option>
                        {exposureArea.map((area) => (
                        <option key={area.id} value={area.id}>
                          {area.name}
                        </option>
                      ))}
                      </select> 
                      {errors.exposureArea && <p className="errorText">{errors.exposureArea}</p>}
                    </div>

                    <div className="form-group my-1 mx-3 px-2">
                      <label htmlFor="">{t('scheduleManagement.exposurePoint')}</label>
                      <br />
                      <select
                        id="exposurePoint"
                        value={addScheduleData.exposurePoint}
                        name="exposurePoint"
                        onChange={handleAddPolicyChange} 
                      >
                        <option value="">--select--</option>
                        {locationPoint.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </select> 
                      {errors.exposurePoint && <p className="errorText">{errors.exposurePoint}</p>}
                    </div> 

                                  
                  </div>

                  <div className="selectWrap">
                      <div className="form-group my-1 mx-4 px-3 policy-class">
                        <label>{t('scheduleManagement.policyName')}</label>
                        <br />
                        <input 
                          id="policyName"
                          value={policydetails[0]?.activity_name}
                          name="policyName"
                          type='text'
                          placeholder='Policy Name'
                          className="placeholder-style"
                          onChange={handleAddPolicyChange}
                          disabled 
                        />
                      </div>

                      <div className="form-group my-1 mx-4 px-3">
                          <label>{t('scheduleManagement.duration')}</label>
                          <br />
                          <input 
                            id="duration"
                            value={policydetails[0]?.duration}
                            name="duration"
                            type='text'
                            placeholder='Duration'
                            className="placeholder-style"
                            onChange={handleAddPolicyChange} 
                            disabled
                          />
                      </div>  

                      <div className="form-group my-1 mx-4 px-3">
                        <label htmlFor="">{t('scheduleManagement.locationType')}</label>
                        <br />
                        <input 
                          id="locationType"
                          value={areaService.getLocationStatus(parseInt(policydetails[0]?.policy_area_type))}
                          name="locationType"
                          type='text'
                          placeholder='Location Type'
                          className="placeholder-style"
                          onChange={handleAddPolicyChange}
                          disabled 
                        />
                      </div> 
                 </div>

                  <div className="selectWrap">
                        <div className="form-group my-1 ">
                        <label>{t('scheduleManagement.fromDate')}</label>
                        <br />
                        <input 
                          id="fromDate"
                          value={addScheduleData.fromDate}
                          name="fromDate"
                          type='date'
                          onChange={handleDateChange} 
                          className="placeholder-style"
                          min={getCurrentDate()}
                        />
                        {errors.lastCalDate && <p className="errorText">{errors.lastCalDate}</p>}     
                        </div> 

                        <div className="form-group my-1">
                          <label>{t('scheduleManagement.toDate')}</label>
                          <br />
                          <input 
                            id="toDate"
                            value={addScheduleData.toDate}
                            name="toDate"
                            type='date'
                            onChange={handleDateChange} 
                            className="placeholder-style"
                          />
                          {errors.nextCalDate && <p className="errorText">{errors.nextCalDate}</p>}     
                        </div> 

                        <div className="form-group my-1">
                          <label>{t('scheduleManagement.fromTime')} <span style={{ color: 'red' }}>*</span></label>
                          <br />
                          <input 
                            id="fromTime"
                            value={addScheduleData.fromTime}
                            name="fromTime"
                            type='time'
                            onChange={handleAddPolicyChange} 
                            className="placeholder-style"
                          />
                          {errors.fromTime && <p className="errorText">{errors.fromTime}</p>}     
                        </div>                                        
                 </div>
                  
                <div className="selectWrap">           
                       <div className="form-group my-1 px-3">
                          <label>{t('scheduleManagement.toTime')}</label>
                          <br />
                          <input 
                            id=""
                            value={endTime}
                            name=""
                            type='text'
                            disabled
                            className="placeholder-style"
                            onChange={handleAddPolicyChange} 
                          />
                        </div> 
                      
                      <div className="form-group my-1 px-5">
                        <label>{t('scheduleManagement.daysOfWeek')} <span style={{ color: 'red' }}>*</span></label>
                            <div className="d-flex">
                            {daysOfWeek.map((day, index) => (
                                <div  key={index}
                                onClick={() => toggleSelection(index)}
                                className={day.selected ? 'selectedDay mx-2' : 'normalDay mx-2' }>
                                    {day.name}
                                </div>
                            ))}
                            </div>
                      <div>
                    </div>
                    {errors.daysOfWeak && <p className="errorText">{errors.daysOfWeak}</p>} 
                      </div>

                      {/* <div style={{"width":"178px"}} className="form-group py-2 mt-3 d-flex">
                        <Form.Check 
                          type="switch"
                          id="custom-switch"
                          className='custom-switch-class'
                          checked={showDateModal}
                          onChange={() => setShowDateModal(!showDateModal)}
                        />
                        <label htmlFor="">All day</label>
                      </div> */}


                      {/* <div className="form-group my-1">
                        <label htmlFor="">{t('scheduleManagement.repeatType')}</label>
                        <br />
                        <select>
                          <option value="">Don't Repeat</option>
                          <option value="">Daily</option>
                          <option value="">Weekly</option>
                          <option value="">Monthly</option>
                          <option value="">Yearly</option>
                          <option value="">Custom</option>
                        </select> 
                        {errors.exposureArea && <p className="errorText">{errors.exposureArea}</p>}
                      </div> */}
                 </div>  

                 <div className="selectWrap">
                  {addScheduleData.id && (
                  <div className="form-group my-1 px-3">
                      <label htmlFor="" className='ReasonLabel'>{t('scheduleManagement.reason')} <span style={{ color: 'red' }}>*</span></label>
                      <br />
                      <input 
                          id="reason"
                          value={addScheduleData.reason}
                          name="reason"
                          type='text'
                          placeholder={t('scheduleManagement.reason')}
                           className="reasonClass"
                          onChange={handleAddPolicyChange} 
                      />
                      {errors.reason && <p className="errorText">{errors.reason}</p>}
                  </div>
                  )}
                  <div className="form-group my-1 px-3" style={{"visibility" : "hidden"}}>
                    <label htmlFor="">{t('userManagement.reason')}</label>
                      <br />
                      <input 
                          id="reason1"
                          // value={addUserData.reason}
                          name="reason1"
                          type='text'
                          placeholder='Reason'
                          className="placeholder-style"
                          // onChange={handleAddUserChange} 
                      />
                  </div>

                  <div className="form-group my-1 px-3" style={{"visibility" : "hidden"}}>
                    <label htmlFor="">{t('userManagement.reason')}</label>
                      <br />
                      <input 
                          id="reason1"
                          // value={addUserData.reason}
                          name="reason1"
                          type='text'
                          placeholder='Reason'
                          className="placeholder-style"
                          // onChange={handleAddUserChange} 
                      />
                  </div>
                </div>

                  {/* 
                 {showDateModal && (<div className='chooseDayWrap'>
                    <div className='d-flex justify-content-between'>
                      <p className='normalText1'>Repeat</p>
                      <TfiClose fontSize={12} onClick={() => setShowDateModal(false)} />
                    </div>
                    <div className='d-flex justify-content-start'>
                      <p className='normalText1 py-1'>Start</p>
                      <input type='date' className='chooseDayDate mx-3' />
                    </div>
                    <div className='d-flex justify-content-start'>
                      <BsArrowRepeat />
                      <p className='normalText1 mx-2'>Repeat every 1 <span><FaChevronDown /></span> week <span><FaChevronDown /></span> </p>
                    </div>
                    <div className='d-flex'>
                    {daysOfWeek.map((day, index) => (
                          <button  key={index}
                          onClick={() => toggleSelection(index)}
                          className={day.selected ? 'selectedDay p-2 mx-1' : 'normalDay p-2 mx-1 ' }>
                              {day.name}
                          </button>
                      ))}
                    </div>
                    <div style={{fontSize: "12px"}}>
                      <p className='m-0 p-0'>occurs every Tuesday and wednesday until
                      <span className='text-info'> may 14, 2024 | remove end date</span></p>
                    </div>
                    <div>
                      <button className='m-2 chooseDateSaveBtn'>save</button>
                      <button onClick={() => setShowDateModal(false)} className='m-2 chooseDateDiscardBtn'>discard</button>
                    </div>

                  </div>)} */}

          </div>
              <div className="bottomArea">
            <button onClick={() => addScheduleClose()} className="modalCancelBtn">
            {t('buttons.cancel')}
            </button>
            <button onClick={() => handlePolicyAddAlert()} className="squareOrangeBtn3">{t('buttons.submit')}</button>
          </div>
            </div>
    </Modal>
  )
}

export default AddScheduleModal