import axios from "axios";
import baseUrl from "..";
import { handleLogOut } from "../../utils/oathUtils";

    let authToken = ""
    let authUserID = ""

    const storedData = localStorage.getItem('userDetails');

    if (storedData) {
      try {
        const storedObject = JSON.parse(storedData);
        if (storedObject && Array.isArray(storedObject) && storedObject.length > 0) {
          authToken = storedObject[0].x_auth_token
          authUserID = storedObject[0].x_userid
        }
      } catch (error) {
        console.error('Failed to parse user details:', error);
      }
    }

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: baseUrl
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      // If the response is successful, just return the response
      return response;
    },
    error => {
      // Check for 400 or 401 status codes
      if (axios.isAxiosError(error) && (error.response?.status === 408 || error.response?.status === 401)) {
        // Redirect to login page
        handleLogOut()
      }
      // Return any other errors
      return Promise.reject(error);
    }
  );


  const ActivityList = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master_activities/list?sort=sort&order=asc&status=active`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const EditById = async (id : string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/workflow/details?workflow_id=${id}`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const WorkflowList = async (page: number , pageSize: number) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/workflow/list?page=${page}&size=${pageSize}&sort=id&status=active,inactive,draft&order=desc&id=`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const ProcessList = async (process_id : string) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/master_process/get_process_by_activity?master_activity_id=${process_id}&status=active,inactive`,
      {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  }; 

  const registerWorkflow = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/workflow/save_workflow_definition`, 
       dataToSend,
       {
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };

  const saveWorkFlowDetails = async (dataToSend:any) => {
    try {
      const response = await axiosInstance.post(`${baseUrl}/workflow/save_workflow_details`, dataToSend ,{
        headers: {
          'x-auth-token': authToken,
          'x-userid': authUserID
        }
      }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response?.data
      }
    }
  };



  const workflow = {
    ActivityList,
    WorkflowList,
    ProcessList,
    registerWorkflow,
    saveWorkFlowDetails,
    EditById
  };
  
  export { workflow };