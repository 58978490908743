//table columns for pending table section 
export const columns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'sl_no',
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    {
      Header: 'Plate Type',
      accessor: 'activity_type',
    },
  ];

  //table columns for history table section 
  export const HistoryColumns: TableColumn[] = [
    {
        Header: 'Sl.No',
        accessor: 'slno',
    },
    {
      Header: 'Plate Serial Number',
      accessor: 'serial_number',
    },
    {
      Header: 'tableCommon.batch_code',
      accessor: 'batch_number',
    },
    // {
    //   Header: 'Area Name',
    //   accessor: 'location_name',
    // },
    {
       Header: 'Updated At',
       accessor: 'updated_at',
    },
  ];
  
  interface TableColumn {
    Header: string;
    accessor: string;
  }
  